<div  class="modal" *ngIf="isVisible">
  <div class="modal-content" style="width: 500px">
    <form nz-form [formGroup]="frmChangePass" (ngSubmit)="submitForm()">
      <div class="headModal">
        <div class="headModalTitle"> Thay đổi mật khẩu </div>
        <div><button class="close" (click)="hide()">&times;</button></div>
      </div>

      <div class="contentModal">
        <label>Mật khẩu hiện tại (<span class="required">*</span>)</label>
        <nz-form-item class="mb-0">
          <nz-form-control [nzErrorTip]="old_password">
            <nz-input-group [nzSuffix]="lock_old">
              <input [type]="type_input1" name="old_password" nz-input formControlName="oldPassword" placeholder="Mật khẩu hiện tại" />
              <ng-template #old_password let-control>
                <ng-container *ngIf="control.hasError('required')">
                  <p class="required"><i>Nhập mật khẩu hiện tại</i></p>
                </ng-container>
              </ng-template>
            </nz-input-group>
            <ng-template #lock_old>
              <i [ngClass]="!frmChangePass.value.oldPassword? 'icon' : 'iconValid'"
                (click)="showPassOld((type_input1 == 'password') ? 'eye' : 'eye-invisible')" nz-icon
                [nzType]="(type_input1 == 'password') ? 'eye' : 'eye-invisible'"></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <label>Mật khẩu mới (<span class="required">*</span>)</label>
        <nz-form-item class="mb-0">
          <nz-form-control [nzErrorTip]="password">
            <nz-input-group [nzSuffix]="lock">
              <input [type]="type_input" name="password" nz-input formControlName="newPassword" placeholder="Mật khẩu mới" />
              <ng-template #password let-control>
                <ng-container *ngIf="control.hasError('required')">
                  <p class="required"><i>Nhập mật khẩu mới</i></p>
                </ng-container>
              </ng-template>
            </nz-input-group>
            <ng-template #lock>
              <i [ngClass]="!frmChangePass.value.newPassword? 'icon' : 'iconValid'"
                (click)="showPass((type_input == 'password') ? 'eye' : 'eye-invisible')" nz-icon
                [nzType]="(type_input == 'password') ? 'eye' : 'eye-invisible'"></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>

        <label>Mật khẩu xác nhận (<span class="required">*</span>)</label>
        <nz-form-item class="mb-0">
          <nz-form-control [nzErrorTip]="confirmed_password">
            <nz-input-group [nzSuffix]="lock">
              <input [type]="type_input" name="confirmed_password" nz-input formControlName="confirmPassword" placeholder="Nhập lại mật khẩu mới" />
              <ng-template #confirmed_password let-control>
                <ng-container *ngIf="control.hasError('required')">
                  <p class="required"><i>Nhập lại mật khẩu mới</i></p>
                </ng-container>
              </ng-template>
            </nz-input-group>
            <ng-template #lock>
              <i [ngClass]="!frmChangePass.value.confirmPassword? 'icon' : 'iconValid'"
                (click)="showPass((type_input == 'password') ? 'eye' : 'eye-invisible')" nz-icon
                [nzType]="(type_input == 'password') ? 'eye' : 'eye-invisible'"></i>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <div *ngIf="message" style="font-family: auto !important;font-size: 13px;font-style: italic;color: #f5222d">
         {{message}}
        </div>
      </div>
      <div class="footerModal">
        <button nz-button class="ant-btn-primary" style="width: 70px" type="submit">Lưu</button>
        <button nz-button nzType="default" style="width: 70px" (click)="hide()" class="btn-df">Bỏ qua</button>
      </div>
    </form>
  </div>
</div>



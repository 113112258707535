import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLoyaltyService } from 'src/app/shared/services/base-loyalty.service';
import { ResponseModel } from 'src/app/shared/models/response';
import { map } from 'rxjs/operators';

@Injectable()
export class SharesService extends BaseLoyaltyService {

  getGame(): Observable<any> {
    return this.get<any>(`/sponsor/game`);
  }

  getThemesGame(): Observable<any> {
    return this.get<any>(`/campaigns/minigame-themes`);
  }

  getPackageSponsor(query): Observable<any> {
    return this.get<any>(`/sponsor`, { params: query });
  }

  getObejctLanding(): Observable<ResponseModel> {
    return this.get<ResponseModel>(`/campaigns/schema`);
  }
  
  actionWhiteList(id: number, query: any, body?: any): Observable<any> {
    return this.post<any>(`/campaigns/${id}/customers/${query}`, body)
  }


  postFile(fileToUpload: File, id: any): Observable<any> {
    const endpoint = `/campaigns/${id}/upload-customers`;
    const formData: FormData = new FormData();
    formData.append('name_file', fileToUpload);
    return this
      .post(endpoint, formData)
      .pipe(map((res) => res));
  }
}

<form nz-form>
  <div class="contentModal">
    <nz-form-item>
      <nz-form-label>Số điện thoại (<span class="required">*</span>)</nz-form-label>
      <nz-form-control>
        <input autocomplete="off" [(ngModel)]="phone" [ngModelOptions]="{standalone: true}" nz-input
          (keyup)="lookingUser()" type="text">
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <p class="required"><i>{{message}}</i></p>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Ảnh</nz-form-label>
      <nz-form-control>
        <div class="logo">
          <img src="{{icheckUser?.avatar_thumbnails?.small}}" alt="" onError="this.src=" assets/stran.png'">
          <span class="name">{{icheckUser?.name}}</span>
        </div>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div class="footerModal">
    <button nz-button class="ant-btn-primary" type="button" (click)="addStaff()">Thêm</button>
    <button nz-button nzType="default" type="button" (click)="cancel()" class="btn-df">Bỏ qua</button>
  </div>
</form>
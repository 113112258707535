import { Component, OnInit, OnDestroy } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from 'src/app/modules/auth/auth.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { RootService } from 'src/app/shared/services/root.service';
import { UpdateBusinessComponent } from 'src/app/shared/components/update-business/update-business.component';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { NzModalService } from 'ng-zorro-antd';
import { ModalOutDateComponent } from 'src/app/shared/modal-out-date/modal-out-date.component';
import { CommonService } from 'src/app/shared/services/common/common.service';
import * as moment from 'moment';
import { DateExpiredComponent } from 'src/app/shared/date-expired/date-expired.component';

declare var $: any;

@Component({
    selector: 'app-loyalty',
    templateUrl: './loyalty.component.html',
    styleUrls: ['./loyalty.component.scss']
})
export class LoyaltyComponent implements OnInit, OnDestroy {
    isCollapsed = true;
    changeTab = false;
    unsubscribe$ = new Subject<void>();
    // subscriptionCampaign: Subscription;
    subscriptionMenu: Subscription;
    menu = [
        {
            title: 'Tổng quan',
            routerLink: '/loyalty/home',
            class: 'iconMenu2',
            nzType: 'line-chart',
            nzTheme: 'outline',
            isChildren: false,
            isActiveSubMenu: false,
            src: './assets/images/menu/tongquan.png'
        },
        {
            title: 'Quản lý chiến dịch',
            // routerLink: '',
            class: 'iconMenu2',
            nzType: 'appstore',
            nzTheme: 'outline',
            isChildren: true,
            isActiveSubMenu: false,
            src: './assets/images/menu/chiendich.svg',
            children: [
                {
                    routerLink: '/loyalty/sell-out/campaign',
                    title: 'Chiến dịch',
                },
                // {
                //     routerLink: '/loyalty/campaigns',
                //     title: 'Kịch bản tặng thưởng',
                // },
                {
                    routerLink: '/loyalty/gifts',
                    title: 'Quà tặng',
                }
            ]
        },
        // {
        //     title: 'Kịch bản tặng thưởng',
        //     routerLink: '/loyalty/campaigns',
        //     class: 'iconMenu2',
        //     nzType: 'appstore',
        //     nzTheme: 'outline',
        //     isChildren: false,
        //     isActiveSubMenu: false,
        //     src: './assets/images/menu/kichbantangthuong.png'
        // },
        // {
        //     title: 'Danh sách quà tặng',
        //     routerLink: '/loyalty/gifts',
        //     class: 'iconMenu2',
        //     nzType: 'gift',
        //     nzTheme: 'outline',
        //     isChildren: false,
        //     isActiveSubMenu: false,
        //     src: './assets/images/menu/danhsachquatang.png'
        // },
        {
            title: 'Quản lý gói voucher',
            // routerLink: '/loyalty/voucher-management',
            class: 'iconMenu2',
            nzType: 'book',
            nzTheme: 'outline',
            isChildren: true,
            isActiveSubMenu: false,
            src: './assets/images/menu/diemthuongcuadoitac.png',
            children: [
                {
                    routerLink: '/loyalty/voucher-management/vouchers',
                    title: 'Danh sách gói voucher',
                },
                {
                    routerLink: '/loyalty/voucher-management/history-voucher',
                    title: 'Lịch sử voucher',
                }
            ]
        },
        {
            title: 'Đối tác bán hàng',
            class: 'iconMenu2',
            nzType: 'user',
            nzTheme: 'outline',
            isChildren: true,
            isActiveSubMenu: false,
            src: './assets/images/menu/doitacbanhang.png',
            children: [
                {
                    routerLink: '/loyalty/partners',
                    title: 'Danh sách đối tác ',
                },
                {
                    routerLink: '/loyalty/partner-waiting',
                    title: 'Đối tác chờ duyệt',
                }
            ]
        },
        {
            title: 'Điểm thưởng của đối tác',
            routerLink: '/loyalty/bonus-points',
            class: 'iconMenu2',
            nzType: 'book',
            nzTheme: 'outline',
            isChildren: false,
            isActiveSubMenu: false,
            src: './assets/images/menu/diemthuongcuadoitac.png'
        },
        {
            title: 'Quà tặng của đối tác',
            // routerLink: '',
            class: 'iconMenu2',
            nzType: 'history',
            nzTheme: 'outline',
            isChildren: true,
            isActiveSubMenu: false,
            src: './assets/images/menu/quatangcuadoitac.png',
            children: [
                {
                    routerLink: '/loyalty/partner-gift/statistic-gift',
                    title: 'Thống kê quà tặng',
                },
                {
                    routerLink: '/loyalty/partner-gift/history',
                    title: 'Ghi nhận quà tặng',
                },
                {
                    routerLink: '/loyalty/partner-gift/bonus-transaction',
                    title: 'Giao dịch trả thưởng',
                }
            ]
        },
        {
            title: 'Đối soát giao dịch VNPAY',
            routerLink: '/loyalty/transaction-control-vnpay',
            class: 'iconMenu2',
            nzType: 'user',
            nzTheme: 'outline',
            isChildren: false,
            isActiveSubMenu: false,
            src: './assets/images/menu/doitacbanhang.png'
        },
        // {
        //     title: 'Cấu hình',
        //     // routerLink: '',
        //     class: 'iconMenu2',
        //     nzType: 'setting',
        //     nzTheme: 'outline',
        //     isChildren: true,
        //     isActiveSubMenu: false,
        //     src: './assets/images/menu/setting.png',
        //     children: [
        //         {
        //             routerLink: '/loyalty/data-sync',
        //             title: 'Cài đặt đồng bộ',
        //         }
        //     ]
        // },
    ];

    constructor(
        private router: Router,
        private authService: AuthService,
        private rootService: RootService,
        private notification: NzNotificationService,
        private modalService: NzModalService,
        public commonService: CommonService,
    ) {
        this.getUser();
    }

    loyaltyUser;
    // tslint:disable-next-line:variable-name
    url_image;
    infoMenuLoyalty: any = '';
    ngOnInit() {
        this.$jquery();
        this.listenChange();
        this.subscriptionMenu = this.commonService.infoMenu.asObservable().subscribe(val => {
            console.log(val);
            this.infoMenuLoyalty = val;
        });

        console.log('meu', this.menu);
    }

    changeMenu() {
        this.changeTab = !this.changeTab;
        this.rootService.saveChangeTab(this.changeTab);
        this.listenChange();
    }

    openSubmenu(isChildren, index) {
        if (isChildren) {
            this.menu[index].isActiveSubMenu = !this.menu[index].isActiveSubMenu;
        }
    }

    listenChange() {
        if (this.changeTab === true) {
            this.isCollapsed = false;
        } else {
            this.isCollapsed = true;
            this.rootService.currentMessage.pipe(takeUntil(this.unsubscribe$)).subscribe(message => {
                if (this.changeTab === true) {
                    this.isCollapsed = false;
                    this.menu.map(item => {
                        return item.class = !this.isCollapsed ? 'iconMenu' : 'iconMenu2';
                    });
                } else {
                    this.isCollapsed = message;
                    this.menu.map(item => {
                        return item.class = !this.isCollapsed ? 'iconMenu' : 'iconMenu2';
                    });
                }
            });
        }
    }

    logout() {
        localStorage.clear();
        this.authService.logout();
        this.router.navigate(['/auth/login']);
        // localStorage.removeItem('loyaltyUser');
        // location.reload();
    }

    ngOnDestroy() {
        this.unsubscribe$.next();
        this.unsubscribe$.complete();
        this.unsubscribe$.unsubscribe();
        // this.subscriptionCampaign.unsubscribe();
        this.subscriptionMenu.unsubscribe();
    }

    $jquery() {
        $('.selected-user').click(function (event) {
            event.stopPropagation();
            $('.icon-down-user').toggleClass('flip');
            $('.box-user').toggle();
        });

        $(window).click(function () {
            if ($('.box-user').css('display') == 'block') {
                $('.box-user').hide();
                $('.icon-down-user').toggleClass('flip');
            }
        });
    }

    updateBusiness() {
        const modal = this.modalService.create({
            nzVisible: true,
            nzTitle: `Thông tin doanh nghiệp`,
            nzContent: UpdateBusinessComponent,
            nzClosable: false,
            nzWidth: 700,
            nzStyle: { top: '30px' },
            nzFooter: null,
            nzMaskClosable: false,
            nzComponentParams: {}
        });
        modal.afterClose.pipe(takeUntil(this.unsubscribe$)).subscribe(result => {
            if (result && result.statusCode === 200) {
                this.notification.create(
                    'success',
                    `Cập nhật thành công!`,
                    `Cập nhật thông tin doanh nghiệp thành công!`
                );
                this.getUser();
            }
            if (result && !result.statusCode) {
                this.notification.create(
                    'error',
                    'Có lỗi xảy ra !!!',
                    `${result}`
                );
            }
        });
    }


    getUser() {
        if (localStorage.getItem('loyaltyUser')) {
            this.loyaltyUser = JSON.parse(localStorage.getItem('loyaltyUser'));
            if (this.loyaltyUser && this.loyaltyUser.logo) {
                this.url_image = this.loyaltyUser.logo.small;
            } else {
                this.url_image = 'assets/images/default-avatar.png';
            }
        }
    }

    // popup hiển thị khi hết thời gian sử dụng
    outDateTime() {
        const modal = this.modalService.create({
            nzVisible: true,
            nzTitle: `Thông báo`,
            nzContent: ModalOutDateComponent,
            nzClosable: false,
            nzWidth: 500,
            nzStyle: { top: '30px' },
            nzFooter: null,
            nzMaskClosable: false,
            nzComponentParams: {}
        });
    }
}

import { environment } from '../environments/environment';
import {ChangeDetectorRef, Component, OnInit, Renderer2} from '@angular/core';
import { Location } from '@angular/common';
import { CommonService } from './shared/services/common/common.service';
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit {

  constructor(
    private location: Location,
    private commonService: CommonService,
    private renderer2: Renderer2,
    private cdref: ChangeDetectorRef
  ) { }
  async ngOnInit() {
    this.location.onUrlChange(e => {
      e = e.split(environment.basePath)[1];
      // if (e === '/loyalty/home') {
      this.commonService.changeMenu(this.getTitleMenu(e));
      // }
    });

    // this.addTrackingId();
    // (window as any).track('hookMessage', (body) => {
    //   console.log('messeage', body);
    // });

  }
  ngAfterContentChecked(){
    this.cdref.detectChanges();
  }

  addTrackingId() {
    const s = this.renderer2.createElement('script');
    s.type = 'text/javascript';
    s.text = `
      !function(t,e,r,n,s,a,o,c){t[s]||(t.GlobalTrackerNamespace=t.GlobalTrackerNamespace||[],t.GlobalTrackerNamespace.push(s),t.GlobalTrackerNamespace.push("https://tracking.tekoapis.com"),t[s]=function(){(t[s].q=t[s].q||[]).push(arguments)},t[s].q=t[s].q||[],(o=e.createElement(r)).async=!0,o.src=n,(c=e.getElementsByTagName(r)[0]).parentNode.insertBefore(o,c),window.onerror=function(e,r,a,o,c){if(r===n)return t[s]("exception",{msg:e,error:c}),!1})}(window,document,"script","https://cdn.tekoapis.com/tracker/dist/v2/tracker.full.min.js","track");
      track("init", '${environment.tracking_id_teko}' )
      track("enableLinkClick");
      track('enableSubmitForm');
      track('enableFocusForm');
    `;
    document.getElementsByTagName('head')[0].appendChild(s);
  }

  getTitleMenu(e) {
    if (e === '/loyalty/home' || e === '/loyalty') {
      return 'TỔNG QUAN';
    }
    if (e === '/loyalty/sell-out/campaign') {
      return 'CHIẾN DỊCH';
    }

    if (e === '/loyalty/campaigns' || e === '/loyalty/campaigns/falling-gift-sellout'
      || e === '/loyalty/campaigns/mini-game-sellout' || e === '/loyalty/campaigns/accumulate-sellout') {
      return 'KỊCH BẢN TẶNG THƯỞNG';
    }

    if (e === '/loyalty/gifts') {
      return 'DANH SÁCH QUÀ TẶNG';
    }
    if (e?.includes('/loyalty/customers')) {
      return 'ĐỐI TÁC BÁN HÀNG';
    }

    if (e === '/loyalty/sell-out/campaign/create') {
      return 'TẠO MỚI CHIẾN DỊCH';
    }
    if (e === '/loyalty/sell-out/bonus-points') {
      return 'ĐIỂM THƯỞNG CỦA ĐỐI TÁC';
    }
  }
}

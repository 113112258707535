export const CAMPAIGN = {
  status: [
    {
      id: 1,
      name: 'ACTIVE',
      key: 'active',
      label: 'Kích hoạt',
    },
    {
      id: 2,
      name: 'WAITING_PAYMENT',
      key: 'waiting_payment',
      label: 'Chờ thanh toán',
    },
    {
      id: 3,
      name: 'WAITING_APPROVE_DONATION',
      key: 'waiting_approve_donation',
      label: 'Chờ duyệt',
    },
    {
      id: 4,
      name: 'APPROVED_DONATION',
      key: 'approved_donation',
      label: 'Duyệt tài trợ',
    },
    {
      id: 5,
      name: 'REFUSED_DONATION',
      key: 'refused__donation',
      label: 'Từ chối tài trợ',
    },
    {
      id: 6,
      name: 'BANNED',
      key: 'banned',
      label: 'Bị dừng',
    },
    {
      id: 7,
      name: 'STOPPED',
      key: 'stopped',
      label: 'Đã dừng',
    }
  ],
  status_transaction: [
    {
      id: 1,
      name: 'SUCCESS',
      key: 'success',
      label: 'Thành công',
    },
    {
      id: 1,
      name: 'PROCESSING',
      key: 'processing',
      label: 'Đang xử lý',
    },
    {
      id: 1,
      name: 'FAIL',
      key: 'fail',
      label: 'Thất bại',
    },
  ],
  type: [
    // {
    //   id: 'receive_gift',
    //   name: 'Kịch bản quét tem/nhập mã nhận quà',
    //   key: 'receive_gift',
    // },
    // {
    //   id: 'mini_game',
    //   name: 'Kịch bản mini game',
    //   key: 'mini_game',
    // },
    // {
    //   id: 'accumulate_point',
    //   name: 'Kịch bản tích luỹ nhận quà',
    //   key: 'accumulate_point',
    // },
    // {
    //   id: 'accumulation_long_term_point',
    //   name: 'Kịch bản tích điểm thành viên',
    //   key: 'accumulation_long_term_point',
    // },
    {
      id: 'receive_gift_qr_mar',
      name: 'Rơi quà',
      key: 'receive_gift_qr_mar',
    },
    {
      id: 'mini_game_qr_mar',
      name: 'Vòng quay may mắn',
      key: 'mini_game_qr_mar',
    },
    // Kịch bản Tích điểm thành viên không giới hạn
    // {
    //   id: 'accumulation_long_term_point_qr_mar',
    //   name: 'Kịch bản tích điểm thành viên không giới hạn',
    //   key: 'accumulation_long_term_point_qr_mar',
    // },
    {
      id: 'accumulate_point_qr_mar',
      name: 'Tích lũy đổi thưởng',
      key: 'accumulate_point_qr_mar',
    }
  ],
  typeVouchers: [
    {
      id: 1,
      name: 'Đang hoạt động',
      key: 'active'
    },
    {
      id: 2,
      name: 'Đang khóa',
      key: 'locked'
    },
    {
      id: 3,
      name: 'Bị khóa',
      key: 'admin_locked'
    }
  ],

  TYPE_STATUS_CUSTOMER : [
    {
      id: 1,
      name: 'Đang hoạt động',
      key: 'enabled'
    },
    {
      id: 2,
      name: 'Đã khoá',
      key: 'disabled'
    },
    // {
    //   id: 3,
    //   name: 'Chờ duyệt',
    //   key: 'pending'
    // },
    // {
    //   id: 4,
    //   name: 'Từ chối',
    //   key: 'reject'
    // }
  ],
  
  TYPE_GIFT: {
    CONGRATULATION: 'Quà tinh thần',
    // ICOIN: 'Quà xu',
    PRODUCT: 'Quà hiện vật',
    RECEIVE_STORE: 'Quà nhận tại cửa hàng',
    PHONE_CARD: 'Quà thẻ điện thoại',
    VOUCHER: 'Quà Voucher',
    DIGITAL_MONEY: 'Tiền thưởng'
  },

  TYPE_PROGRAM: {
    receive_gift: 'Nhận quà',
    mini_game: 'Mini Game',
    accumulate_point: 'Tích lũy nhận điểm',
    accumulation_long_term_point: 'Tích điểm thành viên',
    receive_gift_qr_mar: 'Rơi quà không theo đối tượng',
    mini_game_qr_mar: 'Mini Game không theo đối tượng',
    accumulation_long_term_point_qr_mar: 'Kịch bản tích điểm thành viên không giới hạn',
    accumulate_point_qr_mar: 'Kịch bản tích lũy nhận quà không giới hạn'
  },

  TYPE_CAMPAIGN_ICHECK: [
    {
      id: 'login_required',
      name: 'Đăng nhập tài khoản icheck để tham gia chương trình',
      key: 'login_required'
    },
    {
      id: 'free',
      name: 'Không yêu cầu đăng nhập',
      key: 'free'
    }
  ],

  TYPE_BONUS_POINTS: [
    {
      name: 'Yêu cầu nhập mã dự thưởng',
      key: 'codes'
    },
    {
      name: 'Không yêu cầu nhập mã dự thưởng',
      key: 'nocodes'
    }
  ],

  TYPE_CAMPAIGN_WEBAPP: [
    {
      id: 'info_required',
      name: 'Nhập thông tin khách hàng để tham gia chương trình',
      key: 'info_required'
    },
    {
      id: 'info_otp_required',
      name: 'Nhập thông tin khách hàng và mã OTP để tham gia chương trình',
      key: 'info_otp_required'
    },
    {
      id: 'free',
      name: 'Không yêu cầu nhập thông tin khách hàng',
      key: 'free'
    }
  ],

  statusHistoryVouchers: [
    {
      id: 1,
      name: 'Đã hoàn tác',
      is_rollback: true
    },
    {
      id: 2,
      name: 'Tất cả',
      is_rollback: null,
    },
    // {
    //   id: 3,
    //   name: 'Bị khóa',
    //   key: 3
    // }
  ],

  tooltip: {
    benefit: `Ngân sách được dùng cho loại quà xu và thẻ cào.
    Tổng giá trị quà iPoint và thẻ cào không được phép vượt quá ngân sách của chương trình.`,
    date_active: `Thời gian kích hoạt phải nằm trong khoảng thời gian diễn ra chương trình.`,
    batch_stamp: `Lựa chọn lô tem muốn áp dụng chương trình Loyalty. Lô tem phải được cấu hình áp dụng dịch vụ Loyalty từ hệ thống QR Code`,
    strip_stamp: `Lựa chọn dải tem muốn áp dụng chương trình Loyalty.
    Lô tem chứa dải tem phải được cấu hình áp dụng dịch vụ Loyalty từ hệ thống QR Code.
    Đồng thời, tài khoản QR Code đã liên kết phải có quyền quản lý dài tem này.`,
    stamp: `Lựa chọn tem muốn áp dụng chương trình Loyalty.
    Lô tem chứa mỗi tem phải được cấu hình áp dụng dịch vụ Loyalty từ hệ thống QR Code.
    Đồng thời, tài khoản QR Code đã liên kết phải có quyền quản lý từng tem này.`,
    package: `Chọn gói mã dự thưởng để yêu cầu người dùng nhập mã dự thưởng mới được tặng quà ngẫu nhiên.
    Trường hợp không chọn mã dự thưởng người dùng khi quét mã vạch sản phẩm hoặc truy cập từ URL sẽ được tự động tặng quà ngẫu nhiên.`,
    icoin_product: `Giá trị quà thực tế bằng VNĐ. Khi quà có giá trị cao hết, hệ thống sẽ ưu tiên trả thưởng bằng quà có giá trị thấp hơn.`,
    icoin_icoin: `Số xu và thẻ cào được chuyển từ ngân sách chương trình sang ví của người dùng khi trúng thưởng.`,
    date_export_gift: `Quá thời gian nhận, những quà hiện vật người dùng đã trúng sẽ tự động chuyển sang trạng thái Hủy`,
    package_sponsor: `Chọn gói tài trợ để được sử dụng quà tài trợ trong chương trình.
    Thời gian diễn ra chương trình của bạn bắt buộc phải nằm trong khoảng thời gian quy định của tất cả các gói tài trợ đã chọn.`,
    phone_card: `Số tiền trên thẻ cào được nạp vào số điện thoại của khách hàng theo từng mệnh giá khi trúng thưởng.`,

    // tooltip sellout
    // man tong quan
    campaign: `Số lượng chiến dịch đã có đối tác tham gia trên tổng số lượng chiến dịch đã tạo. `,
    partner: `Tổng số lượng đối tác bán hàng của doanh nghiệp`,
    sales: `Số lượt đối tác xác nhận bán hàng thành công.`,
    reward_record: `Số lượt đối tác được ghi nhận thưởng khi đạt điều kiện bán hàng.`,
    // Danh sách đối tác bán hàng
    sale_partner: `Tổng số lượng đối tác bán hàng của doanh nghiệp.`,
    part_campaign: `Số lượng chiến dịch đã có đối tác tham gia trên tổng số lượng chiến dịch đã tạo.`,
    reward_recordss: `Tổng số lượt đối tác được ghi nhận thưởng khi đạt điều kiện bán hàng.`,
    // Lịch sử ghi nhận thưởng
    total_reward: `Tổng số lượt đối tác được ghi nhận thưởng khi đạt điều kiện bán hàng.`,
    success_reward: `Số lượt đối tác được ghi nhận thưởng và trả thưởng thành công.`,
    fail_reward: `Số lượt đối tác được ghi nhận thưởng nhưng trả thưởng không thành công.`,
    // Lịch sử ghi nhận quà tặng
    bonus_script: `Số lượng kịch bản đã tặng thưởng cho đối tác bán hàng trên tổng số lượng kịch bản đang có.`,
    total_gift_hs: `Tổng số lượng quà tặng đã được ghi nhận cho đối tác.`,
    // Thống kê quà tặng
    total_gift: `Tổng số lượng quà tặng đã được ghi nhận cho đối tác.`,
    gift_paid: `Số lượng quà ghi nhận cho đối tác đã được trả tới đối tác.`,
    unpaid_gift: `Số lượng quà ghi nhận cho đối tác nhưng chưa trả cho đối tác.`,
    gift_refuse: `Số lượng quà ghi nhận cho đối tác nhưng bị từ chối trả quà.`,
    // Đối soát giao dịch vnpay
    total_trans: `Tổng giá trị tiền thưởng trả cho đối tác bán hàng bằng hình thức chi hộ VNPAY.`,
    total_trans_suc: `Tổng giá trị tiền thưởng đã trả thành công cho đối tác bán hàng bằng hình thức chi hộ VNPAY.`,
    total_trans_err: `Tổng giá trị tiền thưởng trả không thành công cho đối tác bán hàng bằng hình thức chi hộ VNPAY.`
  },
  game: {
    times_play: [
      {
        id: 1,
        title: `6 giải`,
        key: 6
      },
      {
        id: 2,
        title: `8 giải`,
        key: 8
      },
      {
        id: 3,
        title: `10 giải`,
        key: 10
      }
    ]
  },
  actionGames: [
    {
      id: 1,
      key: 'icheck://luckywheel?id={campaign_id}',
      name: 'Vào chơi'
    },
    {
      id: 2,
      key: 'icheck://luckywheel?id={campaign_id}',
      name: 'Game là dễ'
    }
  ],
  TYPE_CAMPAIGN_VAL: {
    receive_gift_qr_mar: 'name',
    mini_game_qr_mar: 'turn',
    accumulate_point_qr_mar: 'turn',
    accumulation_long_term_point_qr_mar: 'turn'
  },
  TYPE_CAMPAIGN_CODE: {
    // rơi quà không giới hạn
    receive_gift_qr_mar: 'gift_code',
    // mini game không giới hạn
    mini_game_qr_mar: 'turn_code',
    // tích lũy nhận quà không giới hạn
    accumulate_point_qr_mar: 'point_code',
    // tích điểm thành viên không giới hạn
    accumulation_long_term_point_qr_mar: 'point_code'
  }
};
export const GIFT = {
  type: [
    { value: 'PRODUCT', label: 'Hiện vật', nzDisabled: false },
    // { value: 'ICOIN', label: 'Điểm VNPoint', nzDisabled: false },
    { value: 'CONGRATULATION', label: 'Quà tinh thần', nzDisabled: false },
    { value: 'PHONE_CARD', label: 'Thẻ cào điện thoại', nzDisabled: false },
    // { value: 'RECEIVE_STORE', label: 'Quà nhận tại cửa hàng', nzDisabled: false },
    { value: 'DIGITAL_MONEY', label: 'Tiền thưởng', nzDisabled: false, isViettelCheck: false }
  ],
  type_update: [
    { value: 'PRODUCT', label: 'Hiện vật', nzDisabled: true },
    { value: 'ICOIN', label: 'Điểm VNPoint', nzDisabled: true },
    { value: 'CONGRATULATION', label: 'Quà tinh thần', nzDisabled: true },
    { value: 'PHONE_CARD', label: 'Thẻ cào điện thoại', nzDisabled: true },
    { value: 'RECEIVE_STORE', label: 'Quà nhận tại cửa hàng', nzDisabled: true },
    { value: 'DIGITAL_MONEY', label: 'Tiền thưởng', nzDisabled: true }
  ],
  options: [
    { value: 'qr_code', label: 'Tem QR code' },
    { value: 'barcode', label: 'Mã vạch sản phẩm' },
    { value: 'url', label: 'URL chương trình' }
  ],
  typeGifts: [
    { id: 'chance', value: 'Xác suất nhận quà' },
    { id: 'stamp', value: 'Chọn tem trúng quà' },
    { id: 'code', value: 'Nhập mã nhận quà' }
  ]
};
export const WINNER = {
  status: [
    { value: 'new', label: 'Chưa nhận' },
    { value: 'waiting_receive_gift', label: 'Chờ duyệt' },
    { value: 'received_gift', label: 'Đã nhận' },
    { value: 'refused_gift', label: 'Từ chối' },
    { value: 'canceled', label: 'Hủy' }
  ],
  reason: [
    {value: 'Lý do khác', label: 'Lý do khác'},
    {value: 'Khách hàng không muốn nhận quà', label: 'Khách hàng không muốn nhận quà'},
  ]
};
export const GIFT_MEMBER_STATUS = [
  { key: 'PAUSED', value: 'stop', id: 1, label: 'Bị dừng' },
  { key: 'ACTIVE', value: 'active', id: 2, label: 'Kích hoạt' },
  { key: 'DEACTIVE', value: 'pause', id: 0, label: 'Đã dừng' },
  { key: 'PENDING', value: 'waiting', id: 3, label: 'Chờ duyệt' },
  // { key: 'APPROVE', value: 'approve', id: 4, label: 'Đã duyệt'},
  { key: 'DISAPPROVE', value: 'approve', id: 5, label: 'Không duyệt' }
];
export const CAMPAIGN_STATUS = {
  ACTIVE: 'active',
  WAITING_PAYMENT: 'waiting_payment',
  APPROVED_DONATION: 'approved_donation',
  REFUSED_DONATION: 'refused__donation',
  BANNED: 'banned',
  STOPPED: 'stopped'
};

export const SPONSOR = {
  status: [
    {
      id: 1,
      name: 'ACTIVE',
      key: 'active',
      label: 'Kích hoạt'
    },
    {
      id: 2,
      name: 'WAITING_PAYMENT',
      key: 'waiting_payment',
      label: 'Chờ thanh toán'
    },
    {
      id: 5,
      name: 'BANNED',
      key: 'banned',
      label: 'Bị dừng'
    },
    {
      id: 6,
      name: 'STOPPED',
      key: 'stopped',
      label: 'Đã dừng'
    }
  ],
  tooltip: {
    benefit: `Ngân sách được dùng cho loại quà xu và thẻ cào.
    Tổng giá trị quà iPoint và thẻ cào không được phép vượt quá ngân sách của gói tài trợ.`,
    date_active: `Thời gian kích hoạt phải nằm trong khoảng thời gian diễn ra gói tài trợ.`,
    batch_stamp: `Lựa chọn lô tem muốn áp dụng gói tài trợ Loyalty. Lô tem phải được cấu hình áp dụng dịch vụ Loyalty từ hệ thống QR Code`,
    strip_stamp: `Lựa chọn dải tem muốn áp dụng gói tài trợ Loyalty.
    Lô tem chứa dải tem phải được cấu hình áp dụng dịch vụ Loyalty từ hệ thống QR Code.
    Đồng thời, tài khoản QR Code đã liên kết phải có quyền quản lý dài tem này.`,
    stamp: `Lựa chọn tem muốn áp dụng gói tài trợ Loyalty.
    Lô tem chứa mỗi tem phải được cấu hình áp dụng dịch vụ Loyalty từ hệ thống QR Code.
    Đồng thời, tài khoản QR Code đã liên kết phải có quyền quản lý từng tem này.`,
    package: `Chọn gói mã dự thưởng để yêu cầu người dùng nhập mã dự thưởng mới được tặng quà ngẫu nhiên.
    Trường hợp không chọn mã dự thưởng người dùng khi quét mã vạch sản phẩm hoặc truy cập từ URL sẽ được tự động tặng quà ngẫu nhiên.`,
    icoin_product: `Giá trị quà thực tế bằng VNĐ. Khi quà có giá trị cao hết, hệ thống sẽ ưu tiên trả thưởng bằng quà có giá trị thấp hơn.`,
    icoin_icoin: `Số xu và thẻ cào được chuyển từ ngân sách gói tài trợ sang ví của người dùng khi trúng thưởng.`,
    date_export_gift: `Quá thời gian nhận, những quà hiện vật người dùng đã trúng sẽ tự động chuyển sang trạng thái Hủy`,
    package_sponsor: `Chọn gói tài trợ để được sử dụng quà tài trợ trong gói tài trợ.
    Thời gian diễn ra gói tài trợ của bạn bắt buộc phải nằm trong khoảng thời gian quy định của tất cả các gói tài trợ đã chọn.`,
    phone_card: `Số tiền trên thẻ cào được nạp vào số điện thoại của khách hàng theo từng mệnh giá khi trúng thưởng.`
  },
};
export const SPONSOR_WAITING_APPROVED = {
  status: [
    {
      id: 1,
      name: 'waiting_approve_donation',
      label: 'Chờ duyệt'
    },
    {
      id: 2,
      name: 'approved_donation',
      label: 'Đã duyệt'
    },
    {
      id: 3,
      name: 'refused_donation',
      label: 'Từ chối'
    }
  ]
};
export const ORDER = {
  status: [
    {
      id: 1,
      name: 'WAITING_APPROVE',
      key: 'WAITING_APPROVE',
      label: 'Chờ duyệt'
    },
    {
      id: 2,
      name: 'APPROVED',
      key: 'APPROVED',
      label: 'Đã duyệt'
    },
    {
      id: 3,
      name: 'REFUSED',
      key: 'REFUSED',
      label: 'Từ chối'
    }
  ]
};
export const ICOIN = [
  { value: 100, label: 100 },
  { value: 200, label: 200 },
  { value: 500, label: 500 },
  { value: 1000, label: 1000 },
  { value: 2000, label: 2000 },
  { value: 5000, label: 5000 },
  { value: 10000, label: 10000 },
  { value: 20000, label: 20000 },
  { value: 50000, label: 50000 },
  { value: 100000, label: 100000 },
  { value: 200000, label: 200000 },
  { value: 500000, label: 500000 }
];
export const CARD = [
  { value: 10000, label: 10000 },
  { value: 20000, label: 20000 },
  { value: 50000, label: 50000 },
  { value: 100000, label: 100000 },
  { value: 200000, label: 200000 },
  { value: 500000, label: 500000 }
];
export const DIGITAL_MONEY = [
  // { value: 10, label: 10},
  // { value: 20, label: 20},
  // { value: 50, label: 50},
  // { value: 100, label: 100 },
  // { value: 200, label: 200 },
  // { value: 500, label: 500 },
  // { value: 1000, label: 1000 },
  // { value: 2000, label: 2000 },
  { value: 5000, label: 5000 },
  { value: 10000, label: 10000 },
  { value: 20000, label: 20000 },
  { value: 50000, label: 50000 },
  { value: 100000, label: 100000 },
  { value: 200000, label: 200000 },
  { value: 500000, label: 500000 }
];
export const CONFIG = {
  config_editor: {
    placeholder: '',
    tabsize: 2,
    height: '250px',
    toolbar: [
      ['misc', ['codeview', 'undo', 'redo']],
      ['style', ['bold', 'italic', 'underline', 'clear']],
      ['fontsize', ['fontname', 'fontsize', 'color']],
      ['para', ['style', 'ul', 'ol', 'paragraph', 'height']],
      ['insert', ['link', 'video']]
    ],
    fontNames: ['Helvetica', 'Arial', 'Arial Black', 'Comic Sans MS', 'Courier New', 'Roboto', 'Times']
  }
};

export const WhiteList = {
  status: [
    {
      id: 1,
      name: 'pending',
      label: 'Chờ duyệt'
    },
    {
      id: 2,
      name: 'accepted',
      label: 'Đã duyệt'
    },
    {
      id: 3,
      name: 'rejected',
      label: 'Từ chối'
    }
  ],
  source: [
    {
      id: 1,
      name: 'import',
      label: 'Tải lên'
    },
    {
      id: 2,
      name: 'register',
      label: 'Đăng ký'
    },
    {
      id: 3,
      name: 'qr_slk',
      label: 'QR Code siêu liên kết'
    }
  ]
};

export const optionsDate = [
  {
    id: 'd',
    name: 'Ngày',
    key: 'd'
  },
  {
    id: 'm',
    name: 'Tháng',
    key: 'm'
  },
  {
    id: 'y',
    name: 'Năm',
    key: 'y'
  }
]


<div class="ui-modal-cover display-none" #uiModal>
    <div #uiModalWindow class="ui-modal-window animated">
        <div class="ui-modal-header-wrapper flex">
            <div class="ui-modal-title text-center">
                CHỌN ĐỐI TƯỢNG
            </div>
        </div>
        <div style="position: relative; padding: 20px 20px 0px 20px">
            <div>
                <input name="old_password" nz-input formControlName="oldPassword" placeholder="Tìm đối tượng" />
            </div>
            <div style="margin-top: 10px; height: 250px; overflow-y: auto;">
                <div *ngFor="let data of listOfCustomer">
                    <label nz-checkbox [(ngModel)]="checked">{{data.name}}</label>
                </div>
            </div>
        </div>
        <div class="footerModal">
            <button nz-button nzType="default" (click)="closeModal()" class="btn-cancel">Đóng</button>
            <button nz-button class="btn-add ant-btn-primary" (click)="onSubmit()">Thêm mới</button>
        </div>
    </div>
</div>
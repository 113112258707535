import { CrudCodeService } from 'src/app/shared/services';
import { CommonService } from './../../services/common/common.service';
import { Component, Input, OnInit, Output, EventEmitter, SimpleChanges, ViewChild, ElementRef, Renderer2 } from '@angular/core';

@Component({
  selector: 'app-modal-search-option',
  templateUrl: './modal-search-option.component.html',
  styleUrls: ['./modal-search-option.component.scss']
})
export class ModalSearchOptionComponent implements OnInit {

  constructor(
    private renderer: Renderer2,
    public commonService: CommonService,
    private crudCodeService :CrudCodeService
  ) { }
  checked: boolean = false;
  @Input() show: boolean;
  @Input() customCssAnimation: {
    duration: {
      show: number,
      hide: number
    },
    entranceAnimation: string,
    exitAnimation: string
  } = {
      duration: {
        show: 500,
        hide: 100
      },
      entranceAnimation: 'fadeInDown',
      exitAnimation: 'fadeOutUp'
    };
  @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
  @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
  @Output('close') closeNext: EventEmitter<boolean> = new EventEmitter();
  @ViewChild('uiModal', { static: false }) private uiModal: ElementRef;
  @ViewChild('uiModalWindow', { static: false }) private uiModalWindow: ElementRef;
  timer: any;
  listenForOutsideClick = false;
  listOfCustomer: any = [];

  ngOnInit(): void {
    this.crudCodeService.getCustomer().subscribe(res => {
      this.listOfCustomer = res.data.rows;
    })
  }

  ngOnChanges(changesObj: SimpleChanges) {
    if (changesObj.show && this.uiModalWindow) {
      clearTimeout(this.timer);

      const entranceAnimationClass = this.customCssAnimation.entranceAnimation;
      const exitAnimationClass = this.customCssAnimation.exitAnimation;
      const animationDurationForShow = this.customCssAnimation.duration.show;
      const animationDurationForHide = this.customCssAnimation.duration.hide;

      if (changesObj.show.currentValue === true) {
        this.renderer.removeClass(this.uiModal.nativeElement, 'display-none');
        this.renderer.addClass(this.uiModalWindow.nativeElement, entranceAnimationClass);

        this.timer = setTimeout(() => {
          this.renderer.removeClass(this.uiModalWindow.nativeElement, entranceAnimationClass);
          this.listenForOutsideClick = true;
        }, animationDurationForShow);
      } else {
        this.renderer.addClass(this.uiModalWindow.nativeElement, exitAnimationClass);

        this.timer = setTimeout(() => {
          this.renderer.addClass(this.uiModal.nativeElement, 'display-none');
          this.renderer.removeClass(this.uiModalWindow.nativeElement, exitAnimationClass);
          this.listenForOutsideClick = false;
        }, animationDurationForHide);
      }
    }
  }

  ngOnDestroy() {
    clearTimeout(this.timer);
  }

  closeModal() {
    this.show = false;
    this.closeNext.emit();
    this.showChange.emit(false);
  }

  open() {
    this.show = true;
    this.closeNext.emit();
    this.showChange.emit(true);
  }

  onSubmit(){
    this.show = true;
    this.closeNext.emit();
    this.showChange.emit(true);
  }
}

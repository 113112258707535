import { UploadService } from 'src/app/shared/services/upload.service';
import { NzNotificationService } from 'ng-zorro-antd';
import { Component, Input, OnInit, Output, EventEmitter } from '@angular/core';
const IMG_REGEX = /(.*?)\.(jpg|png|jpeg)$/i;
@Component({
    selector: 'app-upload-img-button',
    templateUrl: './upload-img-button.component.html',
    styleUrls: ['./upload-img-button.component.scss']
})
export class UploadImgButtonComponent implements OnInit {
    @Input() banners: any;
    @Input() required: boolean = false;
    @Output() onReceive = new EventEmitter<any>();
    @Output() onDelete = new EventEmitter<any>();

    constructor(private notificationService: NzNotificationService,
        private upload: UploadService) {
    }

    ngOnChanges(changes: import('@angular/core').SimpleChanges): void {
    }


    ngOnInit(): void {
    }


    onChangeBanner(files) {
        if (files?.length > 0) {
            Array.from(files).forEach(file => {
                if (+this.onGetSizeFile((file as any).size) > 5) {
                    // this.notification('Dung lượng file vượt quá 5MB');
                    this.createNotification('error', 'THÔNG BÁO', 'Dung lượng file vượt quá 5MB');
                } else {
                    if (IMG_REGEX.test((file as any)?.name)) {
                        return this.upload.postImg(file).subscribe((res) => {
                            this.banners = [{
                                src: res.data.url
                            }];
                            this.onReceive.emit({ id: res?.data.url, srcBanner: res?.data.url });
                            this.required = false;
                        });
                    } else {
                        // this.notification('Vui lòng chọn định dạng ảnh JPG, PNG');
                        this.createNotification('error', 'THÔNG BÁO', 'Vui lòng chọn định dạng ảnh JPG, PNG, JPEG');
                    }
                }
            });

            // this.banners = images;
        }
    }

    onRemoveImage() {
        this.banners = [];
        // this.required = true;
        // this.infoForm.get('image').setValue(null);
        // this.isImage = false;
        this.onDelete.emit({ id: null, isRequired: true });
    }

    onGetSizeFile = (size: string) => (+size / (1024 * 1024)).toFixed(2);

    createNotification(type, title, message) {
        this.notificationService.create(type, title, message);
    }

    ngOnDestroy(): void {
    }
}

<div class="modal" *ngIf="isVisible">
    <!-- Modal content -->
    <div class="modal-content" style="width:550px">
      <div class="headModal ">
        <div class="headModalTitle"> {{data.label}}</div>
        <!-- <div>
          <button class="close" (click)="handleCancel()">&times;</button>
        </div> -->
      </div>

      <div class="contentModal clearfix">
        <div class="contentModalLeft">
          <!-- <i nz-icon nzType="question-circle-o" style="color: red;font-size: 28px"></i> -->
        </div>
        <div class="contentModalRight" >
          <div [innerHTML]="data.value" >
          </div>
        </div>
      </div>
      <div class="footerModal">
        <button style="margin-right: 10px; width:200px; height:40px" nz-button nzType="default" (click)="handleCancel()" class="btn-df">Huỷ bỏ</button>
        <button style="background: #733294; color: white; width: 200px; height:40px " nz-button class="ant-btn-primary" type="submit" class="" (click)="onSubmit()">Xác nhận</button>
      </div>
    </div>
</div>

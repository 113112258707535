<div class="ui-modal-cover display-none" #uiModal>
    <div #uiModalWindow
         class="ui-modal-window animated"
         [ngStyle]="{
      'height': height ? height + 'px' : 'auto',
      'width': fullWidth ? '100%' : (width ? width + 'px' : 'auto'),
      'padding': padding + 'px'
    }"
    >
        <div class="ui-modal-header-wrapper flex" *ngIf="hiddenHeader">
            <div class="ui-modal-title text-center">
                <ng-content select="[ui-modal-title]"></ng-content>
            </div>

            <span class="close mr-4 mt-2 cpointer" (click)="close()">
<!--        <i class="fas fa-times" aria-hidden="true"></i>-->
        <app-ui-icon name="times" size="xxl" color="black"></app-ui-icon>
      </span>
        </div>

        <div *ngIf="!isScenario" style="position: relative">
            <div class="icon-modal">
                <div class="img-icon-modal">

                </div>
            </div>
            <img [ngStyle]="{
                 top: !titleModal ? '23%' : '18%'
            }" *ngIf="isSuccess" style="position: absolute; left: 47.3%;" src="assets/images/web_app/icon-modal-success.png">
            <img *ngIf="!isSuccess" style="position: absolute;top: 14%;left: 45.7%;width: 56px;height: 56px;}" src="assets/images/image-fail.png">
            <img [ngStyle]="{
                 height: !titleModal ? '86px' : '100%',
                 width: !titleModal ? '100%' : '100%'
            }" src="./assets/images/background-modal.png">
            
            <div class="text-center title-modal-popup-success" *ngIf="titleModal">{{titleModal}}</div>
        </div>

        <div *ngIf="isScenario" style="position: relative">
            <!-- <img [ngStyle]="{  top: !titleModal ? '23%' : '18%' }" *ngIf="isSuccess" style="position: absolute; left: 47.3%;" src="assets/images/web_app/icon-modal-success.png"> -->
            <img *ngIf="!isSuccess" style="position: absolute;top: 16%;left: 42.7%;width: 56px;height: 56px;" src="assets/images/campaign-v2/fail-icon.png">
            <!-- <img [ngStyle]="{ height: !titleModal ? '86px' : '', width: !titleModal ? '100%' : ''}" src="./assets/images/background-modal.png"> -->
            <img [ngStyle]="{ height: !titleModal ? '86px' : '', width: '100%'}" src="./assets/images/background-modal.png">
            <div [ngStyle]="{ top: '50%' }" class="text-center maxLength title-modal-popup-success" *ngIf="titleModal">{{titleModal}}</div>
        </div>
        <div class="ui-modal-content">
            <ng-content select="[ui-modal-body]"></ng-content>
        </div>

        <div>
            <div class="ui-modal-footer" *ngIf="!hiddenFooter">
                <ng-content select="[ui-modal-footer]"></ng-content>
            </div>
        </div>
    </div>
</div>

import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';
@Injectable({
  providedIn: 'root'
})

export class CampaignSharedService extends BaseLoyaltyService {

  getCampaign(query?: any): Observable<any> {
    return this.get<any>(`/campaigns/campaign-statistic`, { params: query });
  }

  charList(query?: any): Observable<any> {
    // return this.get<any>(`/campaign/statistical/chart`, { params: query });
    return this.get<any>(`/campaigns/reporting`, { params: query });
  }

  chartListColumn(query?: any): Observable<any> {
    // return this.get<any>(`/campaign/statistical/chart`, { params: query });
    return this.get<any>(`sell/campaigns/reporting/best-seller`, { params: query });
  }


  statistical(): Observable<any> {
    return this.get<any>(`/campaigns/statistical/overview`);
  }

  createFallGift(body) {
    return this.post<any>('/campaigns', body);
  }

  getDetailFallGift(id) {
    return this.get<any>(`/campaigns/${id}`);
  }

  detailScenario(campaignId: number, scriptId: number) {
    return this.get<any>(`/campaigns/${campaignId}/scripts/${scriptId}`);
  }

  getDetailCampaign(id) {
    return this.get<any>(`/campaigns/${id}`);
  }

  updateFallGift(id, body) {
    return this.put<any>(`/campaigns/${id}`, body);
  }

  updateCampaign(id, body) {
    return this.put<any>(`/campaigns/${id}`, body);
  }

  createScenario(id?, body?) {
    return this.post<any>('/campaigns', body);
  }

  deleteScript(id, scriptId, body?) {
    return this.post(`/campaigns/${id}/scripts/${scriptId}/delete`,body);
  }

  stopCampaign(idCampaign,scriptId, body): Observable<any> {
    return this.patch<any>(`/campaigns/${idCampaign}/scripts/${scriptId}/status`, body);
  }

  activeCampaign(idCampaign,scriptId, body): Observable<any> {
    return this.patch<any>(`/campaigns/${idCampaign}/scripts/${scriptId}/status`, body);
  }

  updateScenario(id, scriptId, body) {
    return this.put<any>(`/campaigns/${id}/scripts/${scriptId}`, body);
  }

  getBenefit(id) {
    return this.get(`/campaigns/${id}/benefit`);
  }
  // Cập nhật vị trí kịch bản
  updateMapping(id, body) {
    return this.post<any>(`/campaigns/${id}/mapping`, body);
  }
}

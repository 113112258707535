<div class="content">
    <div class="message">
        <h3>Cảnh báo</h3>
        <p class="tex">{{message}}</p>
        <a (click)="accept()">Tôi đã hiểu</a>
    </div>
    <div class="image">
        <img src="assets/images/warning-permission.png" alt="">
    </div>
    <div class="clearfixed"></div>
</div>
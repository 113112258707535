// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // apiCoreOld: 'https://api.dev.icheck.vn/api/v1',
  apiCoreOld: 'https://api.dev.icheck.vn/api/business/public/loyalty',
  // apiCore: 'https://seller.dev.icheck.vn/api',
  apiCoreV2: 'https://sellout-v2.dev.icheck.vn',

  baseSellout: '/api/sellout/api',
  // apiLoyalty: 'https://api.dev.icheck.vn/api/business/loyalty/cms',
  // apiPublicLoyalty: 'https://api.dev.icheck.vn/api/business/public/loyalty',
  // apiNotPublicLoyalty: 'https://api.dev.icheck.vn/api/business/loyalty',
  // apiLoyalty: 'https://apiv2.dev.icheck.vn/api/business/sell/cms',
  apiPublicLoyalty: 'https://apiv2.dev.icheck.vn/api/business/public/sell',
  // apiNotPublicLoyalty: 'https://apiv2.dev.icheck.vn/api/business/sell',
  // urlImage: 'https://upload.icheck.vn/hi/',
  // url_static_image: 'http://icheckcdn.net/images/720x720',
  // url_mkt: 'http://cd-dev.qcheck.vn/',
  // openAppiCheck: 'https://icheckdev.page.link/Home',
  // urlWebapp: 'http://webapp-loyalty.dev.icheck.vn/',
  // urlWebapp: 'http://localhost:8100/'
  // urlWebapp: 'http://10.10.1.105:8100/'
  // urlWebapp: 'http://10.10.1.220:4444/'
  basePath: '/portal',
  tracking_id_teko: '456a844e-d5dc-4ba0-8f0f-e2abddac1ae3',
  QR_SLK: 'https://qrcheck.dev.icheck.vn',
  uploadStream: 'https://upload.icheck.com.vn/upload/stream',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.



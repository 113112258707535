import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BaseService } from './base.service';
import { BehaviorSubject } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class RootService extends BaseService {
  messageSource = new BehaviorSubject<boolean>(true);
  changeTab = new BehaviorSubject<boolean>(false);
  currentMessage = this.messageSource.asObservable();
  currentChangeTab = this.changeTab.asObservable();

  changeMessage(message) {
    this.messageSource.next(message);
  }

  saveChangeTab(changeTab) {
    this.changeTab.next(changeTab);
  }

  getCities(): Observable<any> {
    return this.get<any>(`/api/sellout/api/list-cities`);
  }

  getDistricts(query?: any): Observable<any> {
    return this.get<any>(`api/sellout/api/list-districts`,{params: query});
  }

  getWards(query?: any): Observable<any> {
    return this.get<any>(`api/sellout/api/wards`, {params: query});
  }

  getiCheckUser(search) {
    if (search.length > 8) {
      return this.get<any>(`/users?search=${search}`);
    }
    return this.get<any>(`/users/${search}/profile`);
  }

  getiCheck() {
    return this.get<any>(`/users`);
  }
    // theme
    // getTheme(id: number) {
    //   return this.http.get<any>(`${environment.apiPublicLoyalty}/campaign/${id}/theme_game`);
    // }

    // createTheme(body: any) {
    //   return this.http.post<any>(`${environment.apiPublicLoyalty}/campaign/minigame-themes`, body);
    // }
    //
    // updateTheme(id: number, body: any) {
    //   return this.http.put<any>(`${environment.apiPublicLoyalty}/campaign/minigame-themes/${id}`, body);
    // }
}

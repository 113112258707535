<div class="ui-modal-cover display-none" #uiModal>
    <div #uiModalWindow class="ui-modal-window animated">
        <div class="ui-modal-header-wrapper flex">
            <div class="ui-modal-title text-center">
                Chi hộ VNPAY
            </div>
        </div>

        <div style="position: relative">
            <div class="text-center title-modal-popup-success">
                Bạn cần cung cấp thông tin Mã ví VNPAY để hoàn thiện cài đặt này.
                Vui lòng liên hệ iCheck để được hỗ trợ!</div>
        </div>
        <div>
            <div class="ui-modal-footer">
                <span class="closeShow mr-4 mt-2" (click)="close()">
                    <span>Đóng</span>
                </span>
            </div>
        </div>
    </div>
</div>
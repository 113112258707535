<nz-layout style="height: 100%;">
  <nz-sider appShowMenu nzCollapsible [(nzCollapsed)]="isCollapsed && !changeTab " [nzTrigger]="null" style="height: 100%;position: fixed;
    z-index: 99;">
    <div>
      <div style="height: 100px; margin-top: -15px;">
        <div class="logo" [ngClass]="isCollapsed == false ? 'blockLogo' : 'noneLogo'">
          <img class="logo__default" src="assets/logo_sellout.jpg" routerLink="/loyalty/home">
        </div>
      </div>
    </div>
    <ul class="a" nz-menu nzMode="inline" [nzInlineCollapsed]="isCollapsed">
      <ng-container *ngFor="let item of menu; index as i">
        <li nz-menu-item [routerLink]="item.routerLink" [nzMatchRouter]="!item.isChildren"
          (click)="openSubmenu(item.isChildren, i)">
          <!--          <i [ngClass]="item.class" nz-icon [nzType]="item.nzType" [nzTheme]="item.nzTheme"></i>-->
          <div class="d-flex align-items-center">
            <div class="d-flex align-items-center">
              <img [ngStyle]="{
              marginTop: item.class !== 'iconMenu2' ? '0px' : '10px',
              height: '20px',
              width: '20px'
            }" [ngClass]="item.class" [src]="item.src">
              <span *ngIf="item.class !== 'iconMenu2'" style="margin-left: 10px">{{item.title}}</span>
            </div>
            <div style="position: absolute;right: 0;top: 15px;" *ngIf="item.isChildren && item.class !== 'iconMenu2'">
              <i *ngIf="!item.isActiveSubMenu" nz-icon nzType="caret-down" nzTheme="outline"></i>
              <i *ngIf="item.isActiveSubMenu" nz-icon nzType="caret-up" nzTheme="outline"></i>
            </div>
          </div>
        </li>
        <ul>
          <div *ngIf="item.isActiveSubMenu && item.class !== 'iconMenu2'">
            <span class="sub-menu" *ngFor="let child of item.children" nz-menu-item [routerLink]="child.routerLink"
              [nzMatchRouter]="true">
              {{child.title}}</span>
          </div>
          <!--        <div nz-submenu *ngIf="item.isChildren" [nzTitle]="item.title" [nzIcon]="item.nzType">-->
          <!--            <li *ngFor="let child of item.children" nz-menu-item [routerLink]="child.routerLink" [nzMatchRouter]="true">-->
          <!--              {{child.title}}</li>-->
          <!--        </div>-->
        </ul>
      </ng-container>
    </ul>
  </nz-sider>

  <nz-layout class="nzLayoutMenu" [ngClass]="changeTab ?'ccc' :''">
    <nz-header class="ant-layout-header">
      <i class="trigger icon-menu" nz-icon [nzType]="isCollapsed && !changeTab ? 'menu-unfold' : 'menu-fold'"
        (click)="changeMenu()"></i>
      <div class="info-layout">{{infoMenuLoyalty}}</div>

      <ul class="float-right box-user-parent">
        <li class="info-user">
          <span class="selected-user">
            <img class="avatar-user" src="{{url_image}}">
          </span>
        </li>
        <li style="text-align: left">
          <span class="selected-user">
            <span class="name-user">{{loyaltyUser?.firstName}}</span>
            <img class="icon-down-user" src="assets/images/ic_down_18px.png">
          </span>
        </li>
        <div class="box-user">
          <div class="homee">
            <i nz-icon nzType="user" nzTheme="outline"></i><a class="management-user" (click)="updateBusiness()">Thông
              tin tài khoản</a>
          </div>
          <div class="logoutt">
            <i nz-icon nzType="lock" nzTheme="outline"></i><a class="logout" (click)="change.show()">Thay đổi mật
              khẩu</a>
          </div>
          <div class="logoutt">
            <i nz-icon nzType="logout" nzTheme="outline"></i><a class="logout" (click)="logout()">Đăng xuất</a>
          </div>
        </div>
      </ul>
    </nz-header>
    <!-- <nz-content class="app-content-loyalty"> -->
    <nz-content>
      <div class="wapper">
        <router-outlet></router-outlet>
      </div>
    </nz-content>
    <!-- footer -->
  </nz-layout>
</nz-layout>

<app-change-password #change></app-change-password>
import { Component, OnInit, Output, EventEmitter, Input, ViewChild, ElementRef, Renderer2, SimpleChanges } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-warning-show',
  templateUrl: './warning-show.component.html',
  styleUrls: ['./warning-show.component.scss']
})
export class WarningShowComponent implements OnInit {
    @Input() show: boolean;   
    @Input() customCssAnimation: {
        duration: {
            show: number,
            hide: number
        },
        entranceAnimation: string,
        exitAnimation: string
    } = {
        duration: {
            show: 500,
            hide: 100
        },
        entranceAnimation: 'fadeInDown',
        exitAnimation: 'fadeOutUp'
    };

    // @Input() switchPayMoney: boolean;  
    @Output() showChange: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output() closeEvent: EventEmitter<boolean> = new EventEmitter();
    @Output('payMoneyMethod') payMoneyMethod: EventEmitter<boolean> = new EventEmitter<boolean>();
    @Output('close') closeNext: EventEmitter<boolean> = new EventEmitter();

    @ViewChild('uiModal', { static: false }) private uiModal: ElementRef;
    @ViewChild('uiModalWindow', { static: false }) private uiModalWindow: ElementRef;

    listenForOutsideClick = false;
    timer: any;

    constructor(private renderer: Renderer2,   public commonService: CommonService) { }

    ngOnInit() { }

    ngOnChanges(changesObj: SimpleChanges) {
        if (changesObj.show && this.uiModalWindow) {
            clearTimeout(this.timer);

            const entranceAnimationClass = this.customCssAnimation.entranceAnimation;
            const exitAnimationClass = this.customCssAnimation.exitAnimation;
            const animationDurationForShow = this.customCssAnimation.duration.show;
            const animationDurationForHide = this.customCssAnimation.duration.hide;

            if (changesObj.show.currentValue === true) {
                this.renderer.removeClass(this.uiModal.nativeElement, 'display-none');
                this.renderer.addClass(this.uiModalWindow.nativeElement, entranceAnimationClass);

                this.timer = setTimeout(() => {
                    this.renderer.removeClass(this.uiModalWindow.nativeElement, entranceAnimationClass);
                    this.listenForOutsideClick = true;
                }, animationDurationForShow);
            } else {
                this.renderer.addClass(this.uiModalWindow.nativeElement, exitAnimationClass);

                this.timer = setTimeout(() => {
                    this.renderer.addClass(this.uiModal.nativeElement, 'display-none');
                    this.renderer.removeClass(this.uiModalWindow.nativeElement, exitAnimationClass);
                    this.listenForOutsideClick = false;
                }, animationDurationForHide);
            }
        }
    }

    ngOnDestroy() {
        clearTimeout(this.timer);
    }

    close() {
        this.payMoneyMethod.emit(false);
        this.show = false;
        this.closeNext.emit();
        this.showChange.emit(false);
    }

    open() {
        this.show = true;
        this.closeNext.emit();
        this.showChange.emit(true);
    }
}

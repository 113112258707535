<div class="login" style=" backgroundColor: '#733294'">
  <div class="logo"></div>
  <div class="form">
    <div class="title" style="color: '#733294'">Đăng nhập</div>
    <form nz-form>
      <nz-form-item style="margin-left: 20px;margin-top: 20px">
        <nz-form-control>
          <nz-input-group [nzSuffix]="mail">
            <input [(ngModel)]="user.userName" [ngModelOptions]="{standalone: true}" class="phone" nz-input
              placeholder="Số điện thoại">
          </nz-input-group>
          <ng-template #mail>
            <i [ngClass]="!user.userName? 'icon' : 'iconValid'" nz-icon nzType="phone"></i>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <nz-form-item style="margin-left: 20px;margin-top: 20px">
        <nz-form-control>
          <nz-input-group [nzSuffix]="lock">
            <input [(ngModel)]="user.password" [ngModelOptions]="{standalone: true}" class="password" [type]="password"
              nz-input placeholder="Mật khẩu">
          </nz-input-group>
          <ng-template #lock>
            <i [ngClass]="!user.password? 'icon' : 'iconValid'"
              (click)="showPass((password == 'password') ? 'eye' : 'eye-invisible')" nz-icon
              [nzType]="(password == 'password') ? 'eye' : 'eye-invisible'"></i>
          </ng-template>
        </nz-form-control>
        <div *ngIf="message" [ngStyle]="styleMessage">
          <i>{{message}}</i>
        </div>
      </nz-form-item>
      <nz-form-item style="margin-left: 20px;margin-top: 20px">
        <nz-form-control style="line-height: 40px">
          <a class="forget" routerLink="/auth/forgot" (click)="forgotPassword()">Quên mật khẩu</a>
          <button [ngClass]="((!user.userName || !user.password)? 'btnLogin' : 'btnLoginValid')" nz-button
            (click)="login()">
            <span>Đăng nhập</span></button>
        </nz-form-control>
      </nz-form-item>
    </form>
  </div>
</div>

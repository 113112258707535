<form nz-form [formGroup]="form" (ngSubmit)="onSubmit()">
  <div class="contentModal">
    <nz-form-item>
      <nz-form-label>Tên đại lý - cửa hàng (<span class="required">*</span>)</nz-form-label>
      <nz-form-control [nzErrorTip]="name">
        <input placeholder="VD: Tổng đại lý miền Bắc" nz-input type="text" formControlName="name">
        <ng-template #name let-control>
          <ng-container *ngIf="control.hasError('required') || control.hasError('whitespace')">
            <p class="required"><i>Vui lòng nhập tên đại lý - cửa hàng</i></p>
          </ng-container>
          <ng-container *ngIf="control.hasError('maxlength')">
            <p class="required">Tên đại lý - cửa hàng không được dài quá 255 ký tự</p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Số điện thoại (<span class="required">*</span>)</nz-form-label>
      <nz-form-control [nzErrorTip]="phone">
        <input placeholder="VD: 0987654321" name="phone" nz-input type="text" formControlName="phone">
        <ng-template #phone let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Vui lòng nhập số điện thoại</i></p>
          </ng-container>
          <ng-container *ngIf="control.hasError('pattern')">
            <p class="required">Vui lòng nhập số</p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Địa chỉ</nz-form-label>
      <nz-form-control [nzErrorTip]="address">
        <input placeholder="VD: 48 Lê Văn Lương, Nhân Chính, Thanh Xuân, Hà Nội" nz-input type="text" formControlName="address">
        <ng-template #address let-control>
          <ng-container *ngIf="control.hasError('maxlength')">
            <p class="required">Địa chỉ không được dài quá 255 ký tự</p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Cửa hàng QR Code liên kết</nz-form-label>
      <nz-form-control [nzErrorTip]="store_id">
        <nz-select nzShowSearch nzAllowClear nzPlaceHolder="Chọn cửa hàng của hệ thống QR Code"
          formControlName="store_id" (ngModelChange)="getShopName($event)">
          <nz-option *ngFor="let o of shops" [nzValue]="o.id" [nzLabel]="o.name"></nz-option>
        </nz-select>
        <ng-template #store_id let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Vui lòng chọn cửa hàng QR Code liên kết</i></p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <label nz-checkbox formControlName="reciew_sponsor">Cho phép nhận tài trợ</label> 
      <i nz-icon nzType="question-circle" nzTheme="outline" nz-tooltip nzTooltipTitle="Cho phép nhà phân phối này sử dụng quà từ chương trình tài trợ của doanh nghiệp bạn vào chương trình loyalty do nhà phân phối tự tạo"></i>
    </nz-form-item>

    <nz-card>
      <nz-form-item>
        <nz-form-label>
          Số điện thoại người quản lý (<span class="required">*</span>)
        </nz-form-label>
        <p><i>(số điện thoại phải đã đăng ký trên iCheck)</i></p>
        <nz-form-control>
          <input placeholder="VD: 0987654321" name="phone_manager" #phone_manager nz-input type="text" formControlName="phone_manager" (blur)="checkValidatePhone($event)">
          <ng-template #phone_manager let-control>
            <ng-container *ngIf="control.hasError('phone')">
              <p class="required"><i>Vui lòng nhập số điện thoại quản lý</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('pattern')">
              <p class="required">Vui lòng nhập số</p>
            </ng-container>
          </ng-template>
        </nz-form-control>
        <div>
          <app-i-image style="float: left" [multiple]=false [maxImage]="1" [fileList]="images" fileType="image/jpeg;image/png;image/jpg"
            (change)="uploadImageAttribute($event)"></app-i-image>
          <strong style="margin-top: 40px; float: left;">{{isName}}</strong>
        </div>
      </nz-form-item>
    </nz-card>

  </div>

  <div class="footerModal">
    <button nz-button class="ant-btn-primary" type="submit">{{type === 'create' ? 'Thêm mới' : 'Cập nhật'}}</button>
    <button nz-button nzType="default" type="button" (click)="cancel()" class="btn-df">Bỏ qua</button>
  </div>
</form> 
import { Component, OnInit } from '@angular/core';
import {NzModalRef} from 'ng-zorro-antd';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-warning-leave',
  templateUrl: './warning-leave.component.html',
  styleUrls: ['./warning-leave.component.scss']
})
export class WarningLeaveComponent implements OnInit {
  isCheckAccountVietel = false;
  constructor(
      private modalRef: NzModalRef<WarningLeaveComponent>,
      public commonService: CommonService
  ) { }

  ngOnInit(): void {}
  submit(type: string) {
    this.modalRef.close(type);
  }
}

import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGuard } from './modules/auth/auth.guard';


const routes: Routes = [

  { path: 'auth', loadChildren: () => import('./modules/auth/auth.module').then(m => m.AuthModule)},
  { path: '', redirectTo: 'loyalty', pathMatch: 'full' },
  { path: 'loyalty', loadChildren: () => import('./modules/loyalty/loyalty.module').then(m => m.LoyaltyModule), canActivate: [AuthGuard] }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }

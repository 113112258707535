<div class="nz-breadcrumb">
    <div class="nz-breadcrumb-item"><a routerLink="/loyalty/home">Trang chủ <span><img
                    src="assets/images/sell-out/breadcrumb-next.png"></span></a></div>
    <div class="nz-breadcrumb-item">Quà tặng của đối tác<span><img
                src="assets/images/sell-out/breadcrumb-next.png"></span></div>
    <div class="nz-breadcrumb-item">Giao dịch trả thưởng</div>
</div>

<ng-template #caretDown>
    <i nz-icon nzType="caret-down" nzTheme="outline"></i>
</ng-template>

<div class="loyalty-filter">
    <div class="title">Bộ lọc</div>
    <form nz-form [formGroup]="formSellOut" autocomplete="false" novalidate="true">
        <div class="filter-center">
            <div>
                <input class="filterQ" nz-input placeholder="Nhập tên đối tác, số điện thoại..." nzShowSearch
                    nzAllowClear formControlName="searchName" />
                <nz-select formControlName="gift_type" [nzSuffixIcon]="caretDown" nzShowSearch nzAllowClear
                    nzPlaceHolder="Quà tặng" style="width: 200px; margin-right: 15px;">
                    <nz-option *ngFor="let option of gifts" [nzLabel]="option.label" [nzValue]="option.value">
                    </nz-option>
                </nz-select>

                <nz-select class="filterQ" formControlName="type" [nzSuffixIcon]="caretDown" nzShowSearch nzAllowClear
                    nzPlaceHolder="Loại giao dịch">
                    <nz-option *ngFor="let option of typeDeal" [nzLabel]="option.name" [nzValue]="option.value">
                    </nz-option>
                </nz-select>

                <nz-range-picker style="width: 200px; margin-left: 15px;" formControlName="date" nzFormat="dd/MM/yyyy"
                    [nzPlaceHolder]="['Từ ngày', 'Đến ngày']"></nz-range-picker>

                <button class="resetBtn" title="Làm mới" (click)="resetForm()">
                    <img src="assets/search_reset.png" style="display: inline-block;width: 25px;">
                </button>
            </div>
            <button nz-button (click)="export()" class="export-file" title="Làm mới">
                Xuất báo cáo
            </button>
        </div>
    </form>
</div>



<div class="layoutQ">

    <section>
        <div class="tableQ">
            <nz-table #rowSelectionTable nzSize="middle" [nzData]="listOfAllData" [nzLoading]="loading"
                [nzScroll]="{ x: '1440px'}" [nzTotal]="total" [nzFrontPagination]="false" [(nzPageIndex)]="pageIndex"
                [(nzPageSize)]="pageSize" (nzPageIndexChange)="loadData()" (nzPageSizeChange)="loadData()"
                [nzPageSizeOptions]='' (nzCurrentPageDataChange)="currentPageDataChange($event)">
                <thead>
                    <tr>
                        <th nzAlign="center">ID</th>
                        <th nzAlign="center" nzWidth="300px">Đối tác bán hàng</th>
                        <th nzAlign="center">Số điện thoại</th>
                        <th nzAlign="left" nzWidth="400px">Quà tặng</th>
                        <th nzAlign="center">Loại giao dịch </th>
                        <th nzAlign="center" nzWidth="200px">Số lượng</th>
                        <th nzAlign="right" nzWidth="150px">Giá trị</th>
                        <th nzAlign="center" nzWidth="250px">Thời gian</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let data of rowSelectionTable.data; index as idx">
                        <td nzAlign="center" class="text-color">{{data.id}}</td>
                        <td nzAlign="left">
                            <span class="maxLength">{{ data.customer_name }}</span>
                        </td>
                        <td nzAlign="center">{{data.phone}}</td>
                        <td nzAlign="left">{{data?.gift_name}}</td>
                        <td nzAlign="center">
                            <div class="text-color">{{typeOf(data.winner_status)}}</div>
                        </td>
                        <td nzAlign="center">
                            <div class="text-color">{{data.gift_count}}</div>
                        </td>
                        <td nzAlign="right">
                            <div class="text-color" style="text-align: right;">{{data.total_value | number}}</div>
                        </td>
                        <td nzAlign="center">
                            <div class="text-color">{{data.created_at | date:'HH:mm, dd/MM/yyyy'}}</div>
                        </td>
                </tbody>
            </nz-table>
        </div>
    </section>
</div>
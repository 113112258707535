import { Component, OnInit, Input, OnDestroy } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { NzModalRef, NzNotificationService, NzModalService } from 'ng-zorro-antd';
import { GiftSharedService, CrudCodeService } from '../../services';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { GIFT, ICOIN, CAMPAIGN, CARD, CONFIG, DIGITAL_MONEY } from '../../models/constant';
import { CheckNumberService } from 'src/app/lib';
import { ListComponentComponent } from '../list-component/list-component.component';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-gift-add',
  templateUrl: './gift-add.component.html',
  styleUrls: ['./gift-add.component.scss']
})
export class GiftAddComponent extends ListComponentComponent implements OnInit, OnDestroy {
  constructor(
    private formBuilder: FormBuilder,
    public modalRef: NzModalRef<GiftAddComponent>,
    private giftSharedService: GiftSharedService,
    private checkNumberService: CheckNumberService,
    public modalService: NzModalService,
    public notification: NzNotificationService,
    public commonService: CommonService,
    public router: Router,
    public crudCodeService: CrudCodeService
  ) {
    super(modalService, notification, router, crudCodeService, commonService);
    this.createForm();
  }
  // config summnernote
  public config = CONFIG.config_editor;

  switchValue = true;
  imgVNPOINT: any;
  imgMONEY: any;
  @Input() gift;
  @Input() type;
  subscriptions: Subscription = new Subscription();
  tooltipiCoin = CAMPAIGN.tooltip.icoin_product;
  typeForm;
  frmAddGift: FormGroup;
  typeGifts = GIFT.type;
  valueOptions = [];
  images = [];
  isImage = false;
  flag = 0;

  descriptionProgram = {
    placeholder: 'Mô tả quà',
    width: '1000px',
    value: null,
    size: 6
  };

  errMessage = '';
  valImageVNPoint = {
    10: 'img_079NFpcp4k6CgAOCfTnyd',
    20: 'img_rb2Zzuf3YTtxLN9ui0mh9',
    50: 'img_gWESrNOSwx4vZdN2bNTii',
    100 : 'img_kGvkNsvJB7BZcy7PDEKr7',
    200: 'img_i2-gMbQppfwtYbcAeLjsS',
    500: 'img_PGl45fhp8YlCBnZ8jrlYr',
    1000: 'img_1XIyuzvTPZaqMvsvtvPan',
    2000: 'img_EvH5V4TMPCBioixvbdLw0',
    5000: 'img_PQvWkTYzfo03ms4hjQsKN',
    10000: 'img_7LnRhYNCYE9tYZtMPKWCA',
    20000: 'img_LO5HaJDCx4Kl21EO5hdTn',
    50000: 'img_KmuzEIigkmPoP71xfEo3C',
    100000: 'img_wS72u17Hi-8-2C9zYDxpi',
    200000: 'img_cNnIGUGLeJQccHblh5Z5Y',
    500000: 'img_Tv-LwcjJ8vx32jsucSktU'
  };
  valImageMoney = {
    10: 'img_tFjpWkAUnhX8Hf-4fvqGt',
    20: 'img_CcsW4OUDB-oRvydDJNTwP',
    50: 'img_zAr5wRIkSV9PX1LDdrFce',
    100 : 'img_K-k8Dj7nhJDT1zdJLbKpi',
    200: 'img_DhADsxDapRHdgVUrFn5Ks',
    500: 'img_ywZx6wqh0lVs3kq-5JzGw',
    1000: 'img_fjH6AW4ZXREIO2l8lU5Cc',
    2000: 'img_CWAqt3uniRHGjOzJkwNYw',
    5000: 'img_-pIPvEEiuPlbG8h323qTy',
    10000: 'img_0FTEeqDWJ4clcqCHDzB49',
    20000: 'img_n32oo7JvQM2pVndhVWQgQ',
    50000: 'img_3r6gAUlVAuGcbYUKVNeNH',
    100000: 'img_JkjrWDnJneG0ERr14QrtD',
    200000: 'img_GUreHrsLU37HNyyUA68i-',
    500000: 'img_P5AXV1z8EhBBuwHrnOWpH'
  };

  ngOnInit() {

    /*Neu this.gift co gia tri thi action la UPDATE nguoc lai la INSERT*/
    if (this.gift) {
      this.updateForm();
      this.checkTooltip();
    } else {
      this.createForm();
    }
    this.frmAddGift.get('icoin').valueChanges.subscribe(val => {
      if (val && this.frmAddGift.get('type').value === 'ICOIN') {
        this.imgVNPOINT = `assets/images/vn-point/${val}.png`;
        console.log("val=====>",val);
        
        this.frmAddGift.get('image').setValue(this.valImageVNPoint[val]);
      } else if(val && this.frmAddGift.get('type').value === 'DIGITAL_MONEY'){
        // console.log("[...this.valueOptions].filter(item => item.value === val)?.length", [...this.valueOptions].filter(item => item.value === val)?.length)
        // console.log("this.valueOptions", this.valueOptions)
        // console.log("val", val)
        this.imgMONEY = [...this.valueOptions].filter(item => item.value == val)?.length ? `assets/images/digital-money/${val}.png` : 'assets/images/tienthuong.png';
        this.frmAddGift.get('image').setValue(this.valImageMoney[val] || 'https://assets.icheck.vn/image/2024/app/7/19/02c5a089ad2a5f5116766d049822690a.png');
      } else {
        if (!this.gift && this.frmAddGift.get('type').value === 'ICOIN' || !this.gift && this.frmAddGift.get('type').value === 'DIGITAL_MONEY') {
          this.imgVNPOINT = null;
          this.imgMONEY = null;
          this.frmAddGift.get('image').setValue(null);
        }
      }
    });
  }
  checkTooltip() {
    this.frmAddGift.controls.icoin.setValue(null);
    if (this.frmAddGift.value.type === 'PRODUCT') {
      this.tooltipiCoin = CAMPAIGN.tooltip.icoin_product;
    } else if (this.frmAddGift.value.type === 'ICOIN') {
      this.valueOptions = ICOIN;
      this.tooltipiCoin = CAMPAIGN.tooltip.icoin_icoin;
    } else if (this.frmAddGift.value.type === 'PHONE_CARD') {
      this.valueOptions = CARD;
      this.tooltipiCoin = CAMPAIGN.tooltip.phone_card;
    } else if (this.frmAddGift.value.type === 'DIGITAL_MONEY') {
      this.valueOptions = DIGITAL_MONEY;
    }
  }

  createForm() {
    this.typeForm = 'Thêm';
    this.createFormGift();
  }

  updateForm() {
    this.images = [{
      uid: 1,
      name: '',
      status: 'done',
      url: this.gift.image.small,
      thumbUrl: this.gift.image.small,
      id: this.gift.image.id
    }];
    this.typeForm = 'Sửa';
    this.typeGifts = GIFT.type_update;
    this.createFormGift().then(() => {
      this.frmAddGift.get('name').patchValue(this.gift.name);
      this.frmAddGift.get('image').patchValue(this.gift.image.id);
      this.frmAddGift.get('type').patchValue(this.gift.type);
      this.frmAddGift.get('icoin').patchValue(this.gift.icoin);
      this.frmAddGift.get('description').patchValue(this.gift.description);
      this.frmAddGift.get('active').patchValue(this.gift.active);
    });
  }

  createFormGift() {
    return new Promise((resolve, reject) => {
      this.frmAddGift = this.formBuilder.group({
        name: [null, [
          Validators.required,
          Validators.maxLength(255)
        ]],
        image: [null, [Validators.required]],
        type: ['PRODUCT', [
          Validators.required
        ]],
        icoin: [null, [Validators.required, Validators.min(0), Validators.pattern('[0-9]*')]],
        description: [null],
        active: [true]
      });
      resolve();
    });
  }

  uploadImageAttribute($event) {
    if ($event.fileList) {
      if ($event.type === 'removed') {
        for (let i = 0; i < this.images.length; i++) {
          if (this.images[i].id === $event.file.id) {
            this.images.splice(i, 1);
            this.frmAddGift.controls.image.setValue(null);
          }
        }
      }
      if ($event.type === 'success' && $event.file.status === 'done') {
        this.frmAddGift.controls.image.setValue($event.file.response.id);
        this.isImage = false;
      }
    }
  }

  changeTypeGift(event) {
    this.checkTooltip();
    if (event === 'CONGRATULATION' || event === 'RECEIVE_STORE') {
      this.frmAddGift.controls.icoin.setValue(0);
    }
  }

  submitForm() {
    let checkSpace = false;
    console.log('click');
    /*validate image gift*/
    if (this.frmAddGift.value.image) {
      this.isImage = false;
    } else { this.isImage = true; }

    if(this.frmAddGift.value.icoin) {
      this.frmAddGift.controls.icoin.setValue(parseInt(this.frmAddGift.value.icoin));
    }
    
    for (const key of Object.keys(this.frmAddGift.controls)) {
      this.frmAddGift.controls[key].markAsDirty();
      this.frmAddGift.controls[key].updateValueAndValidity();
    }

    if (this.frmAddGift.value.name && this.frmAddGift.value.name.replace(/\s/g, '') === '') {
      checkSpace = true;
      this.frmAddGift.controls.name.setValue(null);
    }

    if (this.frmAddGift.valid && !checkSpace) {
      if (this.gift) {// update
        const endpoint = `/gifts/${this.gift.id}`;
        this.flag++;
        if (this.flag === 1) {
          this.putData(endpoint, this.frmAddGift.value).then(res => {
            if (res) {
              this.giftSharedService.addDone(true);
              this.frmAddGift.reset();
              this.modalRef.close(res);
              return;
            }
          }, error => {
            this.flag = 0;
          });
        }
      } else {// add
        const endpoint = `/gifts`;
        this.flag++;
        if (this.flag === 1) {
          this.postData(endpoint, this.frmAddGift.value).then(res => {
            if (res) {
              this.giftSharedService.addDone(true);
              this.frmAddGift.reset();
              this.modalRef.close(res);
              return;
            }
          }, error => {
            this.flag = 0;
          });
        }
      }
    }
  }

  checkNumber($event) {
    const isNumber = this.checkNumberService.checkNumberInput($event.target.value);
    if (isNumber.status) {
      this.frmAddGift.get('icoin').patchValue($event.target.value);
    } else {
      this.errMessage = isNumber.message;
    }
  }

  cancel() {
    this.subscriptions.unsubscribe();
    this.frmAddGift.reset();
    this.modalRef.close();
  }

  ngOnDestroy() {
    this.subscriptions.unsubscribe();
  }
}

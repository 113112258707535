<div class="login" style="color: #733294">
  <div class="logo"></div>
  <div class="form">
    <p class="title">Quên mật khẩu</p>
    <form nz-form [formGroup]="frmForgot" autocomplete="false" novalidate="true" (ngSubmit)="submitForm()">
      <p class="description">Nhập Số điện thoại của tài khoản cần thiết lập lại mật khẩu</p>
      <nz-form-item style="margin-top: 20px;">
        <nz-form-control [nzSpan]="24" [nzErrorTip]="username">
          <input nz-input formControlName="phone" placeholder="Số điện thoại" class="username" type="text" />
          <ng-template #username let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="error"><i>Vui lòng nhập số điện thoại</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </nz-form-item>

      <div nz-row style="margin-top: 80px;">
        <div nz-col nzSpan="12">
          <a class="back-login" routerLink="/auth/login">Quay lại đăng nhập</a>
        </div>
        <div nz-col nzSpan="12" style="text-align: right;">
          <button class="save" nz-button type="submit">Tiếp tục</button>
        </div>
      </div>
    </form>
  </div>
</div>

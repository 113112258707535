import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { GIFT } from 'src/app/shared/models/constant';
import { ActivatedRoute, Params, Router } from '@angular/router';
import {Component, OnDestroy, OnInit} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NzModalService, NzNotificationService } from 'ng-zorro-antd';
import { CommonService } from 'src/app/shared/services/common/common.service';
import { ExportComponent } from '../export/export.component';
import { CrudCodeService } from 'src/app/shared/services';
import { ListComponentComponent } from '../../../../shared/components/list-component/list-component.component';
import * as moment from 'moment';
import * as _ from 'lodash';
import {track} from "../../../../helpers/tracking-teko";
import {debounceTime} from "rxjs/operators";
@Component({
  selector: 'app-bonus-transaction',
  templateUrl: './bonus-transaction.component.html',
  styleUrls: ['./bonus-transaction.component.scss']
})
export class BonusTransactionComponent extends ListComponentComponent implements OnInit, OnDestroy {

  formSellOut: FormGroup;
  pageIndex = 1;
  pageSize = 10;
  total = 0;
  offset = 0;
  offsetGift = 0;
  gifts: any = GIFT.type;
  gift_type: any;
  typeDeal: any = [
    {
      name: 'Trả thưởng',
      value: '1'
    },
    {
      name: 'Từ chối trả thưởng',
      value: '0'
    }
  ];
  listOfAllData: any = [];
  // gift_id: any;
  search: string;
  public loading: boolean;
  constructor(
    public modalService: NzModalService,
    public notification: NzNotificationService,
    public router: Router,
    public commonService: CommonService,
    public crudCodeService: CrudCodeService,
    private fb: FormBuilder,
    private route: ActivatedRoute,
    private http: HttpClient
  ) {
    super(modalService, notification, router, crudCodeService, commonService);
  }

  // @ts-ignore
  // @ts-ignore
  ngOnInit(): void {
    this.commonService.changeMenu('GIAO DỊCH TRẢ THƯỞNG');
    this.initForm();
    // this.gift();
    this.route.queryParams.subscribe(params => {
      this.search = params.name;
      this.gift_type = params.gift_type;
      this.formSellOut.get('type').setValue(params.type);
      if (params.phone) {
        this.formSellOut.get('searchName').setValue(params.phone);
        // this.formSellOut.get('gift_type').setValue(1);
        this.formSellOut.get('gift_id').setValue(params.gift_id);
        // this.formSellOut.get('type').setValue(params.type);
      }
    });
    if (this.search) {
      this.formSellOut.get('searchName').setValue(this.search);
    }
    if (this.gift_type) {
      this.formSellOut.get('gift_type').setValue(this.gift_type);
    }

    this.loadData();
    this.formChange('searchName');
    this.formChange('gift_type');
    this.formChange('type');
    this.formChange('date');

    track('trackLoadPageView', {
      screenName: 'bonusTransaction',
      contentType: 'others',
    });
  }

  formChange(param) {
    this.formSellOut.get(param).valueChanges.pipe(debounceTime(1000)).subscribe(val => {
      this.loadData('filter');
      track('siteSearch', {
        keywords: '',
        params: JSON.stringify(this.getQueryParam())
      });
    });
  }

  typeOf(e) {
    if( e == 'received_gift') {
      return 'Trả thưởng'
    } else {
      return 'Từ chối trả thưởng'
    }
  }

  initForm() {
    this.formSellOut = this.fb.group({
      searchName: [''],
      gift_type: [null],
      type: [null],
      date: [null],
      gift_id: [null]
    });
  }

  currentPageDataChange($event: any[]) { }
  resetForm() {
    if (this.search && this.gift_type) {
      this.formSellOut.get('gift_type').setValue(null);
      this.formSellOut.get('date').setValue(null);
      this.formSellOut.get('type').setValue(null);
      this.formSellOut.get('searchName').setValue(this.search);
    } else {
      this.formSellOut.reset('', { emitEvent: false });
    }
    this.loadData();

    track('siteSearch', {
      keywords: '',
      params: JSON.stringify(this.getQueryParam())
    });
  }

  getQueryParam(param?) {
    if (param) {
      this.pageIndex = 1;
    }
    const query = {
      offset: (this.pageIndex - 1) * this.pageSize,
      limit: this.pageSize,
      search: this.formSellOut.get('searchName').value ? this.formSellOut.get('searchName').value : undefined,
      gift_type: this.formSellOut.get('gift_type').value || undefined,
      type: this.formSellOut.get('type').value || undefined,
      gift_id: this.formSellOut.get('gift_id').value || undefined,
    } as any;

    if (this.formSellOut.get('date').value) {
      query.from = new Date(moment(this.formSellOut.get('date').value[0]).startOf('day').toString()).toISOString();
      query.to = new Date(moment(this.formSellOut.get('date').value[1]).endOf('day').toString()).toISOString();
    }

    return this.getQuery(query);
  }

  loadData(param?) {
    const query = this.getQueryParam(param);
    this.getData('/payout-gift/histories', query).then(res => {
      this.listOfAllData = res;
    });
  }

  // loadMoreGift() {
  //   this.gift();
  // }
  // gift() {
  //   const query = {
  //     offset: this.offsetGift,
  //     limit: this.pageSize,
  //   } as any;

  //   this.offsetGift = this.offsetGift + 10;
  //   const queryParam = this.getQuery(query);
  //   this.crudCodeService.getAnyThing('/gifts', queryParam).toPromise().then(res => {
  //     if ((res as any)?.data?.rows.length) {
  //       (res as any)?.data?.rows.forEach(item => {
  //         this.gifts.push({
  //           value: item.id,
  //           name: item.name,
  //           id: item?.id
  //         });
  //       });
  //     }
  //     this.gifts = _.uniqBy(this.gifts, 'id');
  //   });
  // }

  export() {
    const body = this.getQueryParam();
    const endpoint = 'report/pay-gift-history/csv';
    this.http.post(`${environment.apiCoreV2}/api/sellout/api/${endpoint}`, body, { responseType: 'blob'}).subscribe(res => {
        const blob = new Blob([res], { type: 'text/csv' });

        var downloadURL = window.URL.createObjectURL(res);
        var link = document.createElement('a');
        link.href = downloadURL;
        link.download = "pay-gift-history.csv";
        link.click();

    });

  }

  ngOnDestroy() {
    super.ngOnDestroy();
    track('trackUnLoadPageView', {
      screenName: 'bonusTransaction',
      contentType: 'others',
    });
  }
}

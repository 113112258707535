<div class="login">
  <div class="logo"></div>
    <div class="form">
      <p class="title">Đặt lại mật khẩu</p>
      <form nz-form [formGroup]="frmOTP" autocomplete="false" novalidate="true" (ngSubmit)="submitForm()">
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <p class="description">Mã kích hoạt đã được gửi đến số điện thoại</p>
            <h3 class="phone">{{frmOTP.value.phone}}</h3>
            <input nz-input formControlName="otp" placeholder="Mã xác nhận" type="text" />
            <ng-template #otp let-control>
              <ng-container *ngIf="control.hasError('required')">
                <p class="error"><i>Vui lòng nhập mã xác nhận</i></p>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="password" placeholder="Mật khẩu mới" type="password" />
            <ng-template #otp let-control>
              <ng-container *ngIf="control.hasError('required')">
                <p class="error"><i>Vui lòng nhập mật khẩu mới</i></p>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <nz-form-item>
          <nz-form-control [nzSpan]="24">
            <input nz-input formControlName="confirmed_password" placeholder="Xác nhận mật khẩu" type="password" />
            <ng-template #otp let-control>
              <ng-container *ngIf="control.hasError('required')">
                <p class="error"><i>Vui lòng nhập lại mật khẩu</i></p>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
        <p class="error" *ngIf="isNotMatch"><i>Mật khẩu chưa trùng khớp</i></p>
        <div nz-row style="margin-top: 20px;">
          <div nz-col nzSpan="12">
            <a class="back-login" routerLink="/auth/forgot">Lấy lại OTP</a>
          </div>
          <div nz-col nzSpan="12">
            <button class="save" nz-button nzType="default" type="submit">Đặt lại mật khẩu</button>
          </div>
        </div>
      </form>
    </div>
  </div>
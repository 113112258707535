<form nz-form>
    <div class="contentModal">
        <nz-form-item *ngFor="let item of permissionsTest">
            <nz-form-label>{{item.name}}</nz-form-label>
            <nz-form-control>
                <nz-select [(ngModel)]="item.permission_method_code" [ngModelOptions]="{ standalone : true }" nzAllowClear nzPlaceHolder="Chọn quyền"
                    (ngModelChange)="changeCampaignPermission($event)">
                    <nz-option *ngFor="let o of item.permissionMethod" [nzValue]="o.code" [nzLabel]="o.name"></nz-option>
                </nz-select>
            </nz-form-control>
        </nz-form-item>
    </div>

    <div class="footerModal">
        <button nz-button class="ant-btn-primary" type="button" (click)="submitForm()">Cập nhật</button>
        <button nz-button nzType="default" type="button" (click)="cancel()" class="btn-df">Bỏ qua</button>
    </div>
</form>
import { NgModule, ModuleWithProviders } from '@angular/core';
import { NgxSummernoteDirective } from './ngx-summernote.directive';
import { NgxSummernoteViewDirective } from './ngx-summernote-view.directive';

@NgModule({
  declarations: [
    NgxSummernoteDirective,
    NgxSummernoteViewDirective
  ],
  exports: [
    NgxSummernoteDirective,
    NgxSummernoteViewDirective
  ]
})
export class NgxSummernoteCustomModule {
  public static forRoot(): ModuleWithProviders<NgxSummernoteCustomModule> {
    return {ngModule: NgxSummernoteCustomModule,  providers: []};
  }
}

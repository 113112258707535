<nz-alert *ngIf="uploadError" nzType="error" [nzMessage]="uploadError" nzShowIcon></nz-alert>
<nz-upload [(nzFileList)]="fileList" nzListType="picture-card" [nzShowUploadList]="showUploadList"
  [nzMultiple]="multiple" [nzSize]="maxSize" [nzShowButton]="maxImage > fileList.length" [nzCustomRequest]="tusUpload"
  (nzChange)="onChange($event)" [nzBeforeUpload]="beforeUpload">
  <span *ngIf="sizes?.length" style="color: red"> Kích thước:
    {{sizes[0].width}} x
    {{sizes[0].height}} </span>
  <i nz-icon class="colorEye" nzType="plus"></i>
  <div style="color: #733294" class="ant-upload-text">Chọn quà</div>
</nz-upload>

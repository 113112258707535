import {Injectable, OnInit} from '@angular/core';
import { Observable, BehaviorSubject } from 'rxjs';
import { BaseLoyaltyService } from '../base-loyalty.service';
import {CommonService} from "../common/common.service";

@Injectable({
    providedIn: 'root'
})

export class GiftSharedService extends BaseLoyaltyService implements OnInit{
    loyaltyUser: any;
    private doneObjSubject = new BehaviorSubject<boolean>(false);
    addGiftDone = this.doneObjSubject.asObservable();

    addDone(done) {
        this.doneObjSubject.next(done);
    }

    listGift(query?: any): Observable<any> {
        const params = query;
        //
        // ICOIN: 'Quà Xu',
        //     PRODUCT: 'Quà hiện vật',
        //     CONGRATULATION: 'Quà tinh thần',
        //     PHONE_CARD: 'Quà thẻ nạp điện thoại',
        //     RECEIVE_STORE: 'Quà nhận tại cửa hàng',
        //     VOUCHER: 'Voucher',
        // params.type = 'ICOIN,PRODUCT,CONGRATULATION,PHONE_CARD,RECEIVE_STORE,VOUCHER';
        return this.get<any>(`/gifts`, { params });
    }

    listGiftMember(query?: any): Observable<any> {
        return this.get<any>(`/loyalty/gifts`, { params: query });
    }

    listGiftSponsor(query?: any): Observable<any> {
        return this.get<any>(`/sponsor/gifts`, { params: query });
    }

    addGift(body: any) {
        return this.post<any>(`/gifts`, body);
    }

    addGiftMember(body: any) {
        return this.post<any>(`/loyalty/gifts`, body);
    }

    updateGift(body: any, gift: any) {
        return this.put<any>(`/gifts/${gift.id}`, body);
    }

    // tslint:disable-next-line:contextual-lifecycle
    ngOnInit(): void {
    }
}

<!--<div class="banner-campaign-sell-out">-->
<!--    <div class="label">Ảnh banner chiến dịch (<span class="required">*</span>)</div>-->
<div *ngIf="!isUploadV2 && !isUploadImage && !isUploadNeedle && !isUploadRotation && !isUploadBanner">
    <div class="upload">
        <!-- <img [hidden]="banners?.length" (click)="banner.click()" src="./assets/images/sell-out/upload.png"> -->
        <img [hidden]="banners?.length" (click)="banner.click()" src="./assets/images/sell-out/upload-v2.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/sell-out/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/sell-out/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>

<div *ngIf="isUploadV2">
    <div class="upload-v2">
        <img appDragDrop (files)="onChangeBanner($event)" [hidden]="banners?.length" (click)="banner.click()" src="assets/images/upload.svg">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">

        <div [hidden]="!banners?.length" class="update-success">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
</div>



<div *ngIf="isUploadImage">
    <div class="upload">
        <img appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-background.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success-v2">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" style="width:130px; height: 220px;" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')'}"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>


<div *ngIf="isUploadBanner">
    <div class="upload" style="width: 135px">
        <img width="135px" appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-banner.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success-v2">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div class="img-success" style="height: 80px; width: 130px;" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')'}"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>

<div *ngIf="isUploadRotation">
    <div class="upload">
        <img appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-rotation.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success-v2">
            <div style="width:120px" class="img-success" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')',
            height:'120px'
            }"></div>
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>
<div *ngIf="isUploadNeedle">
    <div class="upload">
        <img appDragDrop [hidden]="banners?.length" (files)="onChangeBanner($event)" (click)="banner.click()" src="./assets/images/campaign-v2/upload-needle.png">
        <input hidden #banner type="file" (change)="onChangeBanner($event.target.files)" (click)="$event.target.value=null">
        <div [hidden]="!banners?.length" class="update-success-v2">
            <!--                        <img class="img-success" [src]="banners[0]?.src">-->
            <div style="width:120px" class="img-success" [ngStyle]="{
            'background-image': 'url(' + banners[0]?.src + ')',
            height:  '120px'
            }"></div>
            <!--                        <div [ngStyle]="{'background-image': 'url(' + banners[0]?.src + ')'}"></div>-->
            <img (click)="banner.click()" class="edit-banner" src="./assets/images/campaign-v2/icon-edit.png">
            <img (click)="onRemoveImage()" class="remove-banner" src="./assets/images/campaign-v2/icon-remove.png">
        </div>
    </div>
    <p class="error" *ngIf="required"><i>Vui lòng chọn ảnh</i></p>
</div>

import {Component, OnDestroy, OnInit} from '@angular/core';
import { AuthService } from '../auth.service';
import { Router } from '@angular/router';
import { AuthQrcodeService } from 'src/app/shared/services';
import { environment } from '../../../../environments/environment';
import { NotificationService } from 'src/app/lib';
import {track} from "../../../helpers/tracking-teko";
// import { NotificationService } from 'src/app/lib';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit, OnDestroy {

  constructor(
    private authService: AuthService,
    private router: Router,
    private notiService: NotificationService,
    private authQrcodeService: AuthQrcodeService
  ) {
  }

  user = {
    userName: null,
    password: null,
    remember: false
  };

  message = '';
  loading = false;
  styleMessage = {
    'margin-top': '10px',
    'color': 'red',
    'font-weight': 500,
    'font-size': '13px',
  };

  ngOnInit() {
    const currentUser = this.authService.currentUserValue;
    if (currentUser && currentUser?.token) {
      this.router.navigate(['/loyalty']);
    }

    track('trackLoadPageView', {
      screenName: 'login',
      contentType: 'others',
    });
  }
  password = 'password';
  showPass(input) {
    if (input === 'eye') {
      this.password = 'text';
    } else {
      this.password = 'password';
    }
  }

  login() {
    this.user.userName = this.checkInputSpace(this.user.userName);
    this.user.password = this.checkInputSpace(this.user.password);
    if (this.user.userName && this.user.password) {
      this.authService.login(this.user)
        .subscribe(
          (user: any) => {
            if (+user?.statusCode === 200 && user?.data?.token) {
              const body = {};
              this.authQrcodeService.login(body).subscribe(res => {
                track('alert', {
                  alertType: res?.statusCode === 200 ? 'success' : 'failed',
                  alertMessage: res?.statusCode === 200 ? 'Dang nhap thanh cong' : 'Dang nhap that bai'
                });

                if (res.statusCode === 200) {
                  track('manualTrackInteractionContent', {
                    contentName: 'Dang nhap',
                    interaction: 'click',
                    target: 'Dashboard',
                  });
                  // tslint:disable-next-line:max-line-length
                  this.styleMessage = {
                    'margin-top': '10px',
                    'color': '#01DF01',
                    'font-weight': 500,
                    'font-size': '13px',
                  };
                  this.message = 'Đăng nhập thành công!';
                  localStorage.setItem('loyaltyUser', JSON.stringify(res.data));
                  localStorage.setItem('quantityLevel', JSON.stringify(true));
                  localStorage.setItem('totalQuantity', JSON.stringify(true));
                  this.router.navigate(['/loyalty/home']);
                  } else if (res.statusCode === 403 && res.data && res.data.block) {
                  this.router.navigate(['/auth/blocked']);
                } else {
                  this.message = `${res.message}`;
                }
              });
              return;
            }
            this.message = 'Thông tin đăng nhập không chính xác!';
          },
          error => {
            this.message = 'Thông tin đăng nhập không chính xác!';
          });
    }
  }

  checkInputSpace(input) {
    return input.replace(/\s/g, '');
  }

  forgotPassword() {
    track('manualTrackInteractionContent', {
      contentName: 'Quen mat khau',
      interaction: 'click',
      target: 'resetPasswordPopup',
    });
  }

  ngOnDestroy(): void {
    track('trackUnLoadPageView', {
      screenName: 'login',
      contentType: 'others',
    });
  }


}

import { Injectable } from '@angular/core';
import { BaseLoyaltyService } from '../base-loyalty.service';

@Injectable({
    providedIn: 'root'
})
export class AuthQrcodeService extends BaseLoyaltyService {

    login(body: any) {
        return this.get<any>(`/users/me`, body);
    }
}

import { Injectable } from '@angular/core';
@Injectable()
export class Business {
    public name: string;
    public phone: string;
    public email: string;
    public website: string;
    public logo: {
        id: string,
        small: string,
        thumbnail: string,
        medium: string
    };
    public address: string;
    public city_id: number;
    public district_id: number;
    public ward_id: number;
    public account_qrcode_id?: number;
    public account_qrcode_name?: string;
    public  expired_at: any;
    public entTerminal: string;
}

<div class="container">
    <div nz-row class="content">
        <div nz-col nzXs="8">
            <img src="assets/images/warning-permission.png" />
        </div>
        <div nz-col nzXs="14">
            <span>Tất cả dữ liệu bạn thay đổi sẽ không  lưu. Bạn có chắc chắn muốn rời khỏi trang này?</span>
        </div>
    </div>
    <div class="footerModal">
        <button class="btn back" nz-button nzType="default" (click)="submit('cancel')" style="margin-right: 5px;">
            Bỏ qua
        </button>
        <button style="background: #733294 !important;" nz-button class="ant-btn-primary" type="submit" (click)="submit('approve')">Đồng ý</button>
    </div>
</div>

import { Component, OnInit, Input } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { StaffsService } from '../../services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { CommonService } from '../../services/common/common.service';
import { filter } from 'rxjs/operators';
import * as _ from 'lodash';
@Component({
  selector: 'app-staff-permission',
  templateUrl: './staff-permission.component.html',
  styleUrls: ['./staff-permission.component.scss']
})
export class StaffPermissionComponent implements OnInit {
  constructor(
    public modalRef: NzModalRef<StaffPermissionComponent>,
    private staffsService: StaffsService,
    private notification: NzNotificationService,
    public commonService: CommonService

  ) {
   }

   @Input() staff;
   campaignPermission;
   public permissionMethod;
   public permissions;
   public permissionCampaign = 'READ';

   public permissionsTest = [];

  ngOnInit() {
    this.staffsService.permissionsByStaff(this.staff.icheck_id).subscribe(res => {
      if (res.statusCode === 200 && res.data) {
        // this.permissionsTest = res.data;
        this.permissionsTest =  res.data;
      }
    });
  }

  changeCampaignPermission(event) {
  }

  cancel() {
    this.modalRef.close();
  }

  submitForm() {
    const permissions = [];
    this.permissionsTest.forEach(item => {
      const permissionStaff = {
        permission_id: item.permission_id,
        permission_method_code: item.permission_method_code
      };
      permissions.push(permissionStaff);
    });
    const req = {
      permissions
    };
    this.staffsService.setPermissionsStaff(this.staff.icheck_id, req).subscribe(
      (res) => {
        this.modalRef.close(res);
      },
      (error) => {
        this.notification.create(
          'error',
          'Có lỗi xảy ra !!!',
          `${error}`
        );
      }
    );
  }
}

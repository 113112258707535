<form nz-form [formGroup]="frmExport" (ngSubmit)="submitForm()">
    <div class="contentModal">
      <nz-form-item>
        <nz-form-label>Email (<span class="required">*</span>)</nz-form-label>
        <nz-form-control [nzErrorTip]="email">
          <input autocomplete="off" nz-input type="text" formControlName="email">
          <ng-template #email let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>Vui lòng nhập email</i></p>
            </ng-container>
            <ng-container *ngIf="control.hasError('pattern')">
                <p class="required"><i>Email không đúng định dạng</i></p>
              </ng-container>
            <ng-container *ngIf="control.hasError('maxlength')">
              <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>

        <p><i>Báo cáo sẽ được tự động gửi đến địa chỉ email nhập bên trên ngay khi tống hợp thành công.</i></p>
      </nz-form-item>
    </div>
  
    <div class="footerModal">
      <button nz-button class="ant-btn-primary" type="submit">Xuất báo cáo</button>
      <button nz-button nzType="default" type="button" (click)="cancel()" class="btn-df">Bỏ qua</button>
    </div>
  </form>
import { Component, OnInit, OnDestroy } from '@angular/core';
import { Subject, Subscription } from 'rxjs';
import { CrudCodeService } from '../../services/crud.servive';
import { Router } from '@angular/router';
import { NzNotificationService, NzModalService } from 'ng-zorro-antd';
import { WarningPermissionComponent } from '../warning-permission/warning-permission.component';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-list-component',
  template: ``,
})
export class ListComponentComponent implements OnInit, OnDestroy {
  unsubscribe$ = new Subject<void>();
  public pageIndex: number;
  public pageSize: number;
  public total: number;
  public loading: boolean;
  public listOfAllData: any;
  constructor(
    public modalService: NzModalService,
    public notification: NzNotificationService,
    public router: Router,
    public crudCodeService: CrudCodeService,
    public commonService: CommonService
  ) {
    this.pageIndex = 1;
    this.pageSize = 10;
    this.total = 0;
    this.loading = false;
    this.listOfAllData = [];
  }

  isAllDisplayDataChecked = false;
  isIndeterminate = false;
  listOfDisplayData: any[] = [];
  mapOfCheckedId: { [key: string]: boolean } = {};
  ids: any[] = [];
  isShow: boolean;

  ngOnInit() {
  }

  currentPageDataChange($event: any[]): void {
    this.listOfDisplayData = $event;
    this.refreshStatus();
  }

  refreshStatus(): void {
    this.isAllDisplayDataChecked = this.listOfAllData.length ? this.listOfDisplayData.every(item => this.mapOfCheckedId[item.id]) : false;

    this.isIndeterminate = this.listOfDisplayData.some(item => this.mapOfCheckedId[item.id]) && !this.isAllDisplayDataChecked;
    this.ids = [];
    for (const [key, value] of Object.entries(this.mapOfCheckedId)) {
      if (value === true) {
        this.ids.push(key);
      }
    }
    if (this.ids.length > 0) {
      this.isShow = false;
    } else {
      this.isShow = true;
    }
  }

  checkAll(value: boolean): void {
    this.listOfDisplayData.forEach(item => (this.mapOfCheckedId[item.id] = value));
    this.refreshStatus();
  }

  getQuery(query) {
    Object.keys(query).forEach(key => {
      if (query[key] === undefined || query[key] === null) {
        delete query[key];
      }
    });
    return query;
  }

  getData(endpoint: string, query?: any) {
    this.loading = true;
    return new Promise((resolve, reject) => {
      this.crudCodeService.getAnyThing(endpoint, query).pipe(takeUntil(this.unsubscribe$)).subscribe(
        {
          next: x => {
            this.loading = false;
            if (x.statusCode === 200) {
              this.listOfAllData = x.data?.rows;
              this.total = x.data.count;
              resolve(this.listOfAllData);
            } else if (x.statusCode === 403) {
              this.warning(x.message);
            } else {
              this.notification.create(
                'error',
                `'Có lỗi xảy ra !!!'`,
                `${x.message}`
              );
            }
          },
          error: err => {
            this.loading = false;
            this.notification.create(
              'error',
              `'Có lỗi xảy ra !!!'`,
              `${err}`
            );
            reject(err);
          },
          complete: () => {
            this.loading = false;
          },
        }
      );
    });
  }

  deleteData(endpoint: string, body?: any) {
    return new Promise((resolve, reject) => {
      this.crudCodeService.deletes(endpoint, body).pipe(takeUntil(this.unsubscribe$)).subscribe(
        {
          next: x => {
            if (x.statusCode === 200) {
              this.notification.create(
                'success',
                `Thành công`,
                `${x.data.message}`
              );
            } else {
              this.notification.create(
                'error',
                `'Có lỗi xảy ra !!!'`,
                `${x.data.message}`
              );
            }
          },
          error: err => {
            this.notification.create(
              'error',
              `'Có lỗi xảy ra !!!'`,
              `${err}`
            );
          },
          complete: () => {
            resolve(200);
          },
        }
      );
    });
  }

  showImage(data) {
    if (data && data.image && data.image.medium) {
      window.open(`${data.image.medium}`);
    }
  }

  warning(message?: string) {
    const modal = this.modalService.create({
      nzVisible: true,
      nzContent: WarningPermissionComponent,
      nzClosable: false,
      nzWidth: 500,
      nzMaskClosable: false,
      nzFooter: null,
      nzComponentParams: {
        message
      }
    });
    modal.afterClose.subscribe(() => {});
  }

  postData(endpoint: string, body: any, filter?: any) {
    return new Promise((resolve, reject) => {
      this.crudCodeService.postAnyThing(endpoint, body, filter).pipe(takeUntil(this.unsubscribe$)).subscribe(
        {
          next: x => {
            if (x.statusCode === 200) {
              let message = '';
              if (x.data && x.data.message) {
                message = x.data.message;
              } else {
                message = `Tạo thành công!`;
              }
              this.notification.create(
                'success',
                `Thành công`,
                `${message}`
              );
              resolve(x.data);
            } else if (x.statusCode === 403) {
              this.warning(x.message);
            } else {
              this.notification.create(
                'error',
                `'Có lỗi xảy ra!'`,
                `${x.message}`
              );
            }
          },
          error: err => {
            this.notification.create(
              'error',
              `'Có lỗi xảy ra!'`,
              `${err}`
            );
            reject(err);
          },
          complete: () => {},
        }
      );
    });
  }

  putData(endpoint: string, body: any) {
    return new Promise((resolve, reject) => {
      this.crudCodeService.putAnyThing(endpoint, body).pipe(takeUntil(this.unsubscribe$)).subscribe(
        {
          next: x => {
            if (x.statusCode === 200) {
              this.notification.create(
                'success',
                `Thành công`,
                `Cập nhật thành công!`
              );
              resolve(x.data);
            } else if (x.statusCode === 403) {
              this.warning(x.message);
            } else {
              reject();
              this.notification.create(
                'error',
                `'Có lỗi xảy ra!'`,
                `${x.message}`
              );
            }
          },
          error: err => {
            this.notification.create(
              'error',
              `'Có lỗi xảy ra!'`,
              `${err}`
            );
            reject(err);
          },
          complete: () => {},
        }
      );
    });
  }

  patchData(endpoint: string, body: any) {
    return new Promise((resolve, reject) => {
      this.crudCodeService.patchAnyThing(endpoint, body).pipe(takeUntil(this.unsubscribe$)).subscribe(
        {
          next: x => {
            if (x.statusCode === 200) {
              this.notification.create(
                'success',
                `Thành công`,
                `Cập nhật thành công!`
              );
              resolve(x.data);
            } else if (x.statusCode === 403) {
              this.warning(x.message);
            } else {
              this.notification.create(
                'error',
                `'Có lỗi xảy ra!'`,
                `${x.message}`
              );
            }
          },
          error: err => {
            this.notification.create(
              'error',
              `'Có lỗi xảy ra!'`,
              `${err}`
            );
            reject(err);
          },
          complete: () => {},
        }
      );
    });
  }

  ngOnDestroy() {
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
  }
}

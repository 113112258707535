import { Component, OnInit } from '@angular/core';
import { NzModalRef } from 'ng-zorro-antd';
import { RootService } from '../../services/root.service';
import { StaffsService } from '../../services';
import { NzNotificationService } from 'ng-zorro-antd/notification';
import { formatPhone84 } from 'src/app/helpers';
import { CommonService } from '../../services/common/common.service';

@Component({
  selector: 'app-staff-add',
  templateUrl: './staff-add.component.html',
  styleUrls: ['./staff-add.component.scss']
})
export class StaffAddComponent implements OnInit {
  constructor(
    public modalRef: NzModalRef<StaffAddComponent>,
    private rootService: RootService,
    private staffsService: StaffsService,
    private notification: NzNotificationService,
    public commonService: CommonService

  ) { }

  images = [];
  isImage = false;
  icheckUser = {
    id: undefined,
    name: undefined,
    phone: undefined,
    avatar: undefined,
    avatar_thumbnails: {
      small: undefined
    }
  };
  phone;
  message = '';
  staff;

  ngOnInit() {}

  lookingUser() {
    if (this.phone && this.phone.length < 7) {
      this.message = 'Số điện thoại chưa đúng.';
    }

    if (this.phone && this.phone.length > 11) {
      this.message = 'Số điện thoại chưa đúng.';
    }

    if (this.phone && (this.phone.length > 7) && (this.phone.length < 11)) {
      this.staffsService.findUserSocialByIdOrPhone(null, formatPhone84(this.phone)).subscribe(res => {
        if (res.data) {
          this.message = '';
          this.icheckUser = res.data;
          this.staff = {
            name: this.icheckUser.name,
            phone: this.phone,
            icheck_id: this.icheckUser.id,
            logo: this.icheckUser.avatar
          };
        } else {
          this.message = 'Số điện thoại trên chưa được đăng ký trên app iCheck';
        }
      });
    }
  }

  addStaff() {
    if (!this.phone) {
      this.message = 'Bạn chưa nhập số điện thoại';
    }
    if (this.staff) {
      this.staffsService.createStaff(this.staff).subscribe(
        (res) => {
          this.modalRef.close(res);
        },
        (error) => {
          this.notification.create(
            'error',
            'Có lỗi xảy ra !!!',
            `${error}}`
          );
        }
      );
    }
  }

  cancel() {
    this.modalRef.close();
  }
}

<!-- <form nz-form [formGroup]="frmAddGift" (ngSubmit)="submitForm()">
  <div class="contentModal">
    <nz-form-item>
      <nz-form-label>Tên quà (<span class="required">*</span>)</nz-form-label>
      <nz-form-control [nzErrorTip]="name">
        <input autocomplete="off" nz-input name="name" type="text" formControlName="name">
        <ng-template #name let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Vui lòng nhập tên quà</i></p>
          </ng-container>
          <ng-container *ngIf="control.hasError('maxlength')">
            <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Loại quà (<span class="required">*</span>)</nz-form-label>
      <nz-form-control [nzErrorTip]="type_gift">
        <nz-select formControlName="type" id="type_gift" nzPlaceHolder="Chọn loại quà"
          (ngModelChange)="changeTypeGift($event)">
          <nz-option *ngFor="let o of typeGifts" nzValue="{{o.value}}" nzLabel="{{o.label}}"
            nzDisabled="{{o.nzDisabled}}"></nz-option>
        </nz-select>
        <ng-template #type_gift let-control>
          <ng-container *ngIf="control.hasError('required')">
            <p class="required"><i>Vui lòng chọn loại quà</i></p>
          </ng-container>
        </ng-template>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Giá trị (<span class="required">*</span>) <i [hidden]="frmAddGift.value.type === 'CONGRATULATION'"
          nz-icon nzType="question-circle" nzTheme="outline" nz-tooltip [nzTooltipTitle]="tooltipiCoin"></i>
      </nz-form-label>
      <div *ngIf="frmAddGift.value.type === 'PRODUCT' || frmAddGift.value.type === 'RECEIVE_STORE'">
        <nz-form-control [nzErrorTip]="icoin">
          <input (blur)="checkNumber($event)" nz-input name="coin" type="text" formControlName="icoin"
            [readonly]="typeForm === 'Thêm' ? false : true">
          <ng-template #icoin let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>{{errMessage}}</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </div>

      <div *ngIf="frmAddGift.value.type === 'ICOIN' || frmAddGift.value.type === 'PHONE_CARD'">
        <nz-form-control [nzErrorTip]="icoin">
          <nz-select formControlName="icoin" nzShowSearch nzAllowClear nzPlaceHolder="Giá trị của quà">
            <nz-option *ngFor="let option of valueOptions"
              nzLabel="{{option.label | number}} {{frmAddGift.value.type === 'ICOIN'? 'Xu' : 'VNĐ'}}"
              [nzValue]="option.value" [nzDisabled]="typeForm === 'Thêm' ? false : true"></nz-option>
          </nz-select>
          <ng-template #icoin let-control>
            <ng-container *ngIf="control.hasError('required')">
              <p class="required"><i>Vui lòng nhập giá trị quà</i></p>
            </ng-container>
          </ng-template>
        </nz-form-control>
      </div>

      <label *ngIf="frmAddGift.value.type === 'CONGRATULATION'">
        <span>Không có giá trị</span>
      </label>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Ảnh quà(<span class="required">*</span>)</nz-form-label>
      <nz-form-control>
        <app-i-image [multiple]=false [maxImage]="1" [fileList]="images" fileType="image/jpeg;image/png;image/jpg"
          (change)="uploadImageAttribute($event)"></app-i-image>
        <ng-container *ngIf="isImage">
          <p class="required" style="margin-top: -20px;margin-bottom: -10px;">
            <i>Vui lòng chọn ảnh</i>
          </p>
        </ng-container>
      </nz-form-control>
    </nz-form-item>

    <nz-form-item>
      <nz-form-label>Mô tả</nz-form-label>
      <nz-form-control>
        <input [ngxSummernote]="config" type="text" nz-input formControlName="description" />
      </nz-form-control>
    </nz-form-item>

    <nz-form-item *ngIf="type">
      <nz-form-control>
        <label nz-checkbox formControlName="active">Hiển thị quà</label>
      </nz-form-control>
    </nz-form-item>
  </div>

  <div class="footerModal">
    <button nz-button class="ant-btn-primary" type="submit">{{typeForm}}</button>
    <button nz-button nzType="default" type="button" (click)="cancel()" class="btn-df">Bỏ qua</button>
  </div>
</form> -->


<!-- Thêm mới quà tặng -->
<div class="contentModal">
  <form nz-form [formGroup]="frmAddGift" (ngSubmit)="submitForm()">

    <div nz-row>
      <div nz-col nzSpan="8" class="title">Tên quà (<span class="required">*</span>)</div>
      <div nz-col nzSpan="16">
        <nz-form-item>
          <nz-form-control [nzErrorTip]="name">
            <input autocomplete="off" nz-input name="name" placeholder="Nhập tên quà..." type="text"
              formControlName="name">
            <ng-template #name let-control>
              <ng-container *ngIf="control.hasError('required')">
                <p class="required"><i>Vui lòng nhập tên quà</i></p>
              </ng-container>
              <ng-container *ngIf="control.hasError('maxlength')">
                <p class="required"><i>Vui lòng chỉ nhập tối đa 255 ký tự</i></p>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="8" class="title">Loại quà (<span class="required">*</span>)</div>
      <div nz-col nzSpan="16">
        <nz-form-item>
          <nz-form-control [nzErrorTip]="type_gift">
            <nz-select formControlName="type"  [nzDisabled]="gift" id="type_gift" nzPlaceHolder="Chọn loại quà"
              (ngModelChange)="changeTypeGift($event)">
              <nz-option nzPlaceHolder="Tuỳ chọn loại quà" *ngFor="let o of typeGifts" nzValue="{{o.value}}"
                nzLabel="{{o.label}}" nzDisabled="{{o.nzDisabled}}"></nz-option>
            </nz-select>
            <ng-template #type_gift let-control>
              <ng-container *ngIf="control.hasError('required')">
                <p class="required"><i>Vui lòng chọn loại quà</i></p>
              </ng-container>
            </ng-template>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="8" class="title">Giá trị (VNĐ) (<span class="required">*</span>)</div>
      <div nz-col nzSpan="16">
        <nz-form-item>
          <div *ngIf="frmAddGift.value.type === 'PRODUCT' || frmAddGift.value.type === 'RECEIVE_STORE'|| frmAddGift.value.type === 'DIGITAL_MONEY'">
            <nz-form-control [nzErrorTip]="icoin">
              <input [disabled]="typeForm === 'Thêm' ? false : true" (blur)="checkNumber($event)" placeholder="Nhập giá trị quà..." nz-input name="coin" type="text"
                formControlName="icoin" [readonly]="typeForm === 'Thêm' ? false : true">
              <ng-template #icoin let-control>
                <ng-container *ngIf="control.hasError('required')">
                  <p class="required"><i>{{errMessage}}</i></p>
                </ng-container>
                <ng-container *ngIf="control.hasError('pattern')">
                  <p class="required">Vui lòng nhập số</p>
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>

          <div *ngIf="frmAddGift.value.type === 'ICOIN' || frmAddGift.value.type === 'PHONE_CARD'">
            <nz-form-control [nzErrorTip]="icoin">
              <nz-select [nzDisabled]="typeForm === 'Thêm' ? false : true" formControlName="icoin" nzShowSearch nzAllowClear nzPlaceHolder="Giá trị của quà">
                <nz-option *ngFor="let option of valueOptions"
                  nzLabel="{{option.label | number}} {{frmAddGift.value.type === 'ICOIN' ? 'Xu' : 'VNĐ'}}"
                  [nzValue]="option.value" [nzDisabled]="typeForm === 'Thêm' ? false : true"></nz-option>
              </nz-select>
              <ng-template #icoin let-control>
                <ng-container *ngIf="control.hasError('required')">
                  <p class="required"><i>Vui lòng nhập giá trị quà</i></p>
                </ng-container>
              </ng-template>
            </nz-form-control>
          </div>

          <label style="top: 5px" *ngIf="frmAddGift.value.type === 'CONGRATULATION'">
            <span>Không có giá trị</span>
          </label>
        </nz-form-item>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="8" class="title">Ảnh quà (<span class="required">*</span>)</div>
      <div nz-col nzSpan="16">
        <nz-form-control>
          <!-- <app-i-image [multiple]=false [maxImage]="1" [fileList]="images" fileType="image/jpeg;image/png;image/jpg"
            (change)="uploadImageAttribute($event)"></app-i-image>
          <ng-container *ngIf="isImage">
            <p class="required" style="margin-top: -20px;margin-bottom: -10px;">
              <i>Vui lòng chọn ảnh</i>
            </p>
          </ng-container> -->
          <app-i-image *ngIf="frmAddGift.get('type').value !== 'DIGITAL_MONEY'" [multiple]=false [maxImage]="1" [fileList]="images" fileType="image/jpeg;image/png;image/jpg"
          (change)="uploadImageAttribute($event)"></app-i-image>

        <img class="img-money" *ngIf="frmAddGift.get('type').value === 'DIGITAL_MONEY'" [src]="imgMONEY ? imgMONEY : 'assets/images/tienthuong.png'"/>
        <ng-container *ngIf="isImage && frmAddGift.get('type').value !== 'DIGITAL_MONEY'">
          <p class="required" style="margin-top: -20px;margin-bottom: -10px;">
            <i>Vui lòng chọn ảnh</i>
          </p>
        </ng-container>
        </nz-form-control>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="8" class="title">Mô tả</div>
      <div nz-col nzSpan="16">
        <nz-form-control>
          <input nzPlaceholder="Nhập mô tả..." [ngxSummernote]="config" type="text" nz-input
            formControlName="description" />
        </nz-form-control>
      </div>
    </div>

    <div nz-row>
      <div nz-col nzSpan="8" class="title">Hiển thị quà </div>
      <div nz-col nzSpan="16">
        <nz-form-item *ngIf="type">
          <nz-form-control>
            <nz-switch [(ngModel)]="switchValue" formControlName="active"></nz-switch>
          </nz-form-control>
        </nz-form-item>
      </div>
    </div>

    <div class="footer-button">
      <button (click)="cancel()" class="btn back">
        <span class="title-info">Hủy bỏ</span>
      </button>
      <!-- [disabled]="!frmAddGift.valid"  -->
      <button type="submit"class="btn next">
        <span class="title-info">Xác nhận</span>
      </button>
    </div>
  </form>
</div>
